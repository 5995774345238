import React from 'react';
import { Table as AntTable, Pagination, Row, Col, Select } from 'antd';
import styles from '../../styles/Registers.module.css';
import { AddOrEditBuyer } from './AddOrEditBuyer';
import { ActionsTableColCommon } from './ActionsTableColCommon';
import { Arrow } from '../../../components/Arrow';

export const Table = ({
  buyers,
  loading,
  totalRows,
  currentPage,
  pageSize,
  onPaginationChange,
  onPageSizeChange,
  onClone,
  onEdit,
  onDelete,
  orderValue,
  setOrderValue
}) => {
  const { Option } = Select;
  const currentStateFrom = currentPage !== 1 ? (currentPage - 1) * pageSize + 1 : 1;
  const currentStateTo = currentPage * pageSize < totalRows ? currentPage * pageSize : totalRows;
  var showing;
  if (totalRows) {
    showing = 'Prikazano ' + currentStateFrom + '-' + currentStateTo + ' od ' + totalRows;
  } else {
    showing = '';
  }
  const orderingFunction = (columnKey) => {
    if (orderValue !== columnKey) {
      setOrderValue(columnKey);
    } else {
      setOrderValue('-' + columnKey);
    }
  };
  const renderTitle = (name, title) => {
    return (
      <Row>
        <Col xs={23} sm={23}>
          <span>{title}</span>
        </Col>

        <Col xs={1} sm={1}>
          {!orderValue.includes(name) ? (
            <Row style={{ marginTop: '-5px' }}>
              <Col xs={24} sm={24} style={{ height: '10px' }}>
                <Arrow rotate={true} color={'#bbbbc1'} />
              </Col>
              <Col xs={24} sm={24}>
                <Arrow rotate={false} color={'#bbbbc1'} />
              </Col>
            </Row>
          ) : orderValue === name ? (
            <Row>
              <Col xs={24} sm={24}>
                <Arrow rotate={false} color={'#1abc9c'} />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs={24} sm={24} style={{ height: '10px' }}>
                <Arrow rotate={true} color={'#1abc9c'} />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    );
  };
  const renderActions = (record) => {
    return (
      <ActionsTableColCommon
        buyer={record}
        onClone={onClone}
        onEdit={onEdit}
        onDelete={onDelete}
        renderModal={(commonProps) => <AddOrEditBuyer {...commonProps} />}
      />
    );
  };
  const columns = [
    {
      title: renderTitle('name', 'Naziv'),
      key: 'name',
      dataIndex: 'name',
      fixed: 'left',
      onHeaderCell: (column) => {
        return {
          onClick: () => {
            orderingFunction(column.key);
          }
        };
      }
    },
    {
      title: renderTitle('vat_no', 'PDV'),
      key: 'vat_no',
      dataIndex: 'vat_no',
      onHeaderCell: (column) => {
        return {
          onClick: () => {
            orderingFunction(column.key);
          }
        };
      }
    },
    {
      title: renderTitle('reg_no', 'PIB'),
      key: 'reg_no',
      dataIndex: 'reg_no',
      onHeaderCell: (column) => {
        return {
          onClick: () => {
            orderingFunction(column.key);
          }
        };
      }
    },
    {
      title: renderTitle('address', 'Adresa'),
      key: 'address',
      dataIndex: 'address',
      onHeaderCell: (column) => {
        return {
          onClick: () => {
            orderingFunction(column.key);
          }
        };
      }
    },
    {
      title: renderTitle('city', 'Grad'),
      key: 'city',
      dataIndex: 'city',
      onHeaderCell: (column) => {
        return {
          onClick: () => {
            orderingFunction(column.key);
          }
        };
      }
    },
    {
      title: 'Poš. broj',
      key: 'postal_code',
      dataIndex: 'postal_code',
      width: '90px'
    },
    {
      title: 'Sajt',
      key: 'website',
      dataIndex: 'website',
      render: (text, record) => (
        <a href={'//' + text} target="_blank" rel="noreferrer">
          {text}
        </a>
      )
    },
    {
      title: 'Kontakt osoba',
      key: 'contact_person',
      dataIndex: 'contact_person'
    },
    {
      title: 'Mejl',
      key: 'contact_mail',
      dataIndex: 'contact_mail'
    },
    {
      title: 'Akcije',
      key: 'action',
      width: '120',
      fixed: 'right',
      render: (record) => renderActions(record)
    }
  ];
  return (
    <div>
      <div className={styles.registersTable}>
        <AntTable
          columns={columns}
          dataSource={buyers}
          loading={loading}
          locale={{ emptyText: 'Nema rezultata' }}
          pagination={false}
          scroll={{ x: true }}
          rowKey={({ id }) => id}
        />
      </div>
      <div className={styles.tableFooter}>
        <div className={styles.paginationDesription}>{showing}</div>
        <div className={'ant-table-pagination ant-table-pagination-right ' + styles.paginationBackground}>
          <Pagination total={totalRows} current={currentPage} onChange={onPaginationChange} showSizeChanger={false} pageSize={pageSize} />
          <Select
            className={styles.pageSizeButton}
            defaultValue={String(pageSize)}
            style={{ width: 130, alignSelf: 'end' }}
            onChange={(e) => onPageSizeChange(e)}
          >
            <Option value="10">10 po strani</Option>
            <Option value="20">20 po strani</Option>
            <Option value="50">50 po strani</Option>
            <Option value="100">100 po strani</Option>
          </Select>
        </div>
      </div>
    </div>
  );
};
