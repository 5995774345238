import React, { useState, useEffect, useRef } from 'react';
import { EditableTable } from './EditableTable';
import { Spinner } from '../../../components/Spinner';
import { showApiErrors } from '../../../utils/showApiErrors';
import { Modal, Popover, Button, Form, Input, Row, Col, DatePicker, Select } from 'antd';
import { buyersService } from '../../../services/buyers';
import moment from 'moment';
import styles from '../../styles/Registers.module.css';
import { AutoComplete } from './AutoComplete';
import { getSelectOptionsWithOrWithoutImg } from '../../../utils/options';
import { useContext } from 'react';
import { CompaniesAndCountriesContext } from '../../../contexts/companiesAndCountriesContext';
import { invoicesService } from '../../../services/invoices';
import { openErrorNotification, openSuccessNotification, openWarningNotification } from '../../../utils/notifications';
import { useHistory } from 'react-router-dom';
import { registersService } from '../../../services/registers';
import Decimal from 'decimal.js-light';
import { TransactionOutlined } from '@ant-design/icons';

const dateFormat = 'DD/MM/YYYY';
const { TextArea } = Input;

export const AddorEditInvoice = (props) => {
  const history = useHistory();
  const { countries } = useContext(CompaniesAndCountriesContext);
  const [items, setItems] = useState([]);
  const [invoiceId, setInvoiceId] = useState(false);
  const [IsForSending, setIsForSending] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState({});
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [initialCustomerName, setInitialCustomerName] = useState();
  const [note, setNote] = useState('');
  const [autocompleteValues, setAutocompleteValues] = useState({ customer_name: '', customer_reg_no: '' });
  const [initialCustomerPib, setInitialCustomerPib] = useState();
  const [enableEdit, setEnableEdit] = useState(!props.match.params.id || props.location.state);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [isCanceled, setisCanceled] = useState(false);
  const [isNegative, setisNegative] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [registers, setRegisters] = useState([]);
  const [mailAddress, setMailAddress] = useState('');
  const [mailList, setMailList] = useState([]);
  const [reqVat, setReqVat] = useState(false);
  const [reqReg, setReqReg] = useState(true);
  const [requireMessagePib,setRequireMessagePib] = useState('Popunite PIB!');

  const [form] = Form.useForm();
  const currentDateTime = new Date();
  const currentDay = currentDateTime.getDate() > 10 ? currentDateTime.getDate() : '0' + currentDateTime.getDate();
  const currentDate = currentDay + '/' + (currentDateTime.getMonth() + 1) + '/' + currentDateTime.getFullYear();
  const ref = useRef(null);

  

  
  useEffect(() => {
    async function getCustomers() {
      setLoading(true);
      try {
        const { results } = await buyersService.getAllBuyers();
        setCustomers(results);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setLoading(false);
      }
    }
    async function getRegisters() {
      setLoading(true);
      try {
        const { results } = await registersService.getAllRegisters();
        setRegisters(results);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setLoading(false);
      }
    }
    getCustomers();
    getRegisters();
  }, []);
  const updateRequiredFields = ()=>{
    if(form.getFieldValue('customer_country')==141){
      setRequireMessagePib('Popunite PIB!');
      setReqVat(false);
      setReqReg(true);
    }else{
      setRequireMessagePib('Popunite PIB ili PDV!');
      if((!form.getFieldValue('customer_reg_no') |form.getFieldValue('customer_reg_no')=='' )& (form.getFieldValue('customer_vat_no')!=undefined &form.getFieldValue('customer_vat_no')!='')){
        
        setReqReg(false);
        setReqVat(true);
        

      }else if ((!form.getFieldValue('customer_reg_no') | form.getFieldValue('customer_reg_no')=='')  & (!form.getFieldValue('customer_vat_no')| form.getFieldValue('customer_vat_no')=='')){
        setReqReg(true);
        setReqVat(true);
       }else{ 
        setReqReg(true);
        setReqVat(false);
       }
    }
  };
  const autoCompleteUpdate = (data) => {
    const { customer_name, customer_reg_no } = data;
    form.setFieldsValue(data);
    setAutocompleteValues({ customer_name, customer_reg_no });
    updateRequiredFields();
  };
  useEffect(() => {
    if (Number(props.match.params.id)) {
      fetchInvoiceDetails(props.match.params.id);
    }
  }, [props.match.params.id, form]);

  useEffect(() => {
    form.validateFields(['customer_reg_no']);
  }, [reqReg,requireMessagePib]);
  useEffect(() => {
    form.validateFields(['customer_vat_no']);
  }, [reqVat]);

  const fetchInvoiceDetails = async (id) => {
    setLoading(true);
    try {
      const res = await invoicesService.getInvoiceInfo(id);
      const {
        items,
        int_number,
        number,
        pre_number,
        date,
        due_date,
        sum_prices,
        sum_taxes,
        total,
        customer_name,
        customer_reg_no,
        customer_vat_no,
        customer_address,
        customer_city,
        customer_country,
        receipt,
        pre_receipt,
        note
      } = res;
      if (res.status === 'closed') {
        setIsClosed(true);
        if(res.total<0){
          setisNegative(true);
        }
        setIsForSending(receipt || pre_receipt);
      }else if (res.status=== 'canceled'){
        setisCanceled(true);
      }
      setNote(note);
      setNote(note);
      setSelectedInvoice(res);
      items.forEach((item, index) => {
        item.key = index + 1;
        /*if (!isNaN(item.tax)) {
          item.tax = parseFloat(item.tax).toFixed(4);
        }*/
      });
      setItems(items);
      setInvoiceId(id);
      const dueDate = moment(due_date, 'YYYY-MM-DD');
      if(props.location.state){
        form.setFieldsValue({
        number:null,
        int_number:null,
        pre_number:null,
        date:null,
        due_date:moment(),
        sum_prices,
        sum_taxes,
        total,
        customer_name,
        customer_reg_no,
        customer_vat_no,
        customer_address,
        customer_city,
        customer_country
      });
      }else{
      form.setFieldsValue({
        number,
        int_number,
        pre_number,
        date,
        due_date: dueDate,
        sum_prices,
        sum_taxes,
        total,
        customer_name,
        customer_reg_no,
        customer_vat_no,
        customer_address,
        customer_city,
        customer_country
      });}
      setAutocompleteValues({ customer_name, customer_reg_no });
    } catch (e) {
      //console.log(e);
    } finally {
      setLoading(false);
    }
  };
  const disabledDate = (current) => {
    return current.startOf('day').isBefore(moment().startOf('day'));
  };
  const transformData = async (fromForm, items) => {
    if (items.length===0){
    return Promise.reject('Unesite stavku!');
    }
    items.forEach(function (item) {
      delete item.key;
    });
    const {
      int_number,
      customer_name,
      customer_reg_no,
      due_date,
      customer_vat_no,
      customer_address,
      customer_city,
      customer_country,
      sum_prices,
      sum_taxes,
      total,
      date
    } = fromForm;
    let dataForPost = {
      int_number,
      customer_name,
      customer_reg_no,
      due_date,
      sum_prices,
      sum_taxes,
      total,
      customer_vat_no,
      customer_address,
      customer_city,
      customer_country,
      items,
      note: note
    };
    if (invoiceId) {
      dataForPost = { ...dataForPost };
    }
    dataForPost.due_date = dataForPost.due_date.format('DD/MM/YYYY');
    return dataForPost;
  };
  useEffect(() => {
    calculateFields();
  }, [items]);
  const calculateFields = async () => {
    let sum_prices = new Decimal(0),
      sum_taxes = new Decimal(0),
      total = new Decimal(0);
    items.map((item) => {
      let discountDecimal = item.discount ? new Decimal(1).minus(item.discount / 100).valueOf() : 1;
      sum_prices = sum_prices.add(new Decimal(item.unit_price).times(item.qty).times(discountDecimal).valueOf());
      total = total.add(item.amount);
      sum_taxes = sum_taxes.add(
        new Decimal(item.unit_price)
          .times(item.qty)
          .times(discountDecimal)
          .times(!isNaN(item.tax) ? item.tax / 100 : 1)
      );
      // sum_prices += Number(item.qty * item.unit_price);
      // total += Number(item.amount);
      // sum_taxes += Number((item.amount - item.qty * item.unit_price).toPrecision(4));
    });
    // sum_taxes = parseFloat(sum_taxes.toPrecision(4)).toFixed(4);
    // total = parseFloat(total.toPrecision(4)).toFixed(4);
    sum_taxes = sum_taxes.toFixed(4).replace(/0{1,2}$/, '');
    total = total.toFixed(4).replace(/0{1,2}$/, '');
    sum_prices = sum_prices.toFixed(4).replace(/0{1,2}$/, '');
    form.setFieldsValue({ sum_prices, total, sum_taxes });
  };
  const onSubmit = async (values) => {
    setButtonLoading(true);
    
    let res;
    try {
      const data = await transformData(values, items);
      res = invoiceId && !props.location.state ? await invoicesService.editReceipts(invoiceId, data) : await invoicesService.addReceipts(data);
      // TODO redirect on add
      if (res.success) {
        openSuccessNotification({ message: 'Uspješno uređeno!' });
        setEnableEdit(false);
      } else {
        openErrorNotification({ message: res.data.message });
      }
    } catch (e) {
      if(e === 'Unesite stavku!'){
        openErrorNotification({message:e});
      }else{
      showApiErrors(e);
      }
    } finally {
      if (res && res.data && res.data.id && (!invoiceId || props.location.state)) {
        history.push('/invoices/edit/' + res.data.id);
      } else {
        //await fetchInvoiceDetails(props.match.params.id);
        setButtonLoading(false);
      }
    }
  };
  const fiscalize = async () => {
    Modal.confirm({
      maskClosable: true,
      title: 'Da li ste sigurni da želite da fiskalizujete fakturu?',
      okText: 'Da',
      cancelText: 'Ne',
      onOk: async () => {
        openWarningNotification({ message: 'Zahtjev se obrađuje.' });
        setLoading(true);
        setIsModalVisible(false);
        var result;
        try {
          const res = await invoicesService.fiscalizeReceipts(invoiceId);
          var timer = false;
          var fiscalizeInterval = setInterval(async () => {
            if (timer) {
              clearInterval(fiscalizeInterval);
              result = await invoicesService.getInvoiceInfo(props.match.params.id);
              let current_status = result.status;
              if (current_status === 'fiscal_started') {
                openWarningNotification({ message: 'Zahtjev se još uvijek obrađuje. Provjerite kasnije.' });
              } else if (current_status === 'closed') {
                openSuccessNotification({ message: 'Uspješno.' });
              } else {
                openErrorNotification({ message: 'Došlo je do greške. Potražite pomoć' });
              }
              await fetchInvoiceDetails(props.match.params.id);
              setLoading(false);
            }
            try {
              result = await invoicesService.getInvoiceInfo(props.match.params.id);
              if (result.status === 'closed') {
                clearInterval(fiscalizeInterval);
                await fetchInvoiceDetails(props.match.params.id);
                openSuccessNotification({ message: 'Uspješno.' });
                setLoading(false);
              }
            } catch {
              openErrorNotification({ message: res });
              clearInterval(fiscalizeInterval);
            }
          }, 1000);
          setTimeout(() => {
            timer = true;
          }, 60000);
        } catch (e) {
          showApiErrors(e);
        }
      }
    });
  };
  const revokeInvoice = async () => {
    Modal.confirm({
      maskClosable: true,
      title: 'Da li ste sigurni da želite da stornirate fakturu?',
      okText: 'Da',
      okType: 'danger',
      cancelText: 'Ne',
      onOk: async () => {
        try {
            await invoicesService.cancelReceiptFiscalize(invoiceId);
            openSuccessNotification({ message: 'Uspješno uređeno!' });

            setEnableEdit(false);
        } catch (e) {
          showApiErrors(e);
        } finally {
          await fetchInvoiceDetails(props.match.params.id);
            history.push('/invoices');
          setButtonLoading(false);
        }
      }
    });
  };
  const sendMail = async () => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (mailAddress) {
      mailList.push(mailAddress);
    }
    setMailList([...mailList]);
    setMailAddress('');
    for (const email of mailList) {
      if (!re.test(String(email).toLowerCase())) {
        openErrorNotification({ message: 'Vaša lista sadrži nevalidne e-mail adrese!' });
        return;
      }
    }
    try {
      await invoicesService.sendReceiptsViaEmail(mailList.join(), invoiceId, isClosed);
      openSuccessNotification({ message: 'Uspješno uređeno!' });
    } catch (e) {
      showApiErrors(e);
    } finally {
      setIsModalVisible(false);
      setMailList([]);
    }
  };
  const onChangePibValue = (name, value) => {
    if(form.getFieldValue('customer_country')== 141 ){
    value = (value || '').replace(/[^0-9^]+/g, '');
    }
    setAutocompleteValues({ ...autocompleteValues, [name]: value });
    let formValues = {};
    formValues[name] = value;
    form.setFieldsValue(formValues);
    updateRequiredFields();
  };
  const onChangeCustomerNameValue = (name, value) => {
    setAutocompleteValues({ ...autocompleteValues, [name]: value });
    let formValues = {};
    formValues[name] = value;
    form.setFieldsValue(formValues);
  };
  const confirmCancelInvoiceForm = () => {
    Modal.confirm({
      maskClosable: true,
      title: 'Da li ste sigurni da želite da odustanete, izmjene neće biti sačuvane?',
      okText: 'Da',
      okType: 'danger',
      cancelText: 'Ne',
      onOk: async() => {
       if( props.match.params.id && !props.location.state) {
        await fetchInvoiceDetails(props.match.params.id);
        setEnableEdit(false);
      }
       else{
          history.push('/invoices');
       }
      }
    });
  };
  const saveInvoice = async () => {
    const hasInputStarted = await ref.current.checkItemsForm();
    await calculateFields();
    if (hasInputStarted) {
      /*Modal.confirm({
        maskClosable: true,
        title: 'Započeto unošenje nove stavke. Sačuvaj bez nje?',
        okText: 'Da',
        okType: 'danger',
        cancelText: 'Ne',
        onOk: () => {
          form.submit();
        }
      });*/
      form.submit();
    } else {
      //form.submit();
    }
  };
  // const handleModalOk = async () => {
  //   try {
  //     const res = await invoicesService.cancelReceiptFiscalize(invoiceId);
  //     if (res.success) {
  //       openSuccessNotification({ message: 'Uspješno uređeno!' });
  //       setEnableEdit(false);
  //     } else {
  //       openErrorNotification({ message: res.data.message });
  //     }
  //   } catch (e) {
  //     showApiErrors(e);
  //   } finally {
  //     await fetchInvoiceDetails(props.match.params.id);
  //     setButtonLoading(false);
  //   }
  // };
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };
  const listEmailAddresses = () => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return mailList.map((elem, index) => {
      return (
        <span key={'email-elem-no-' + index}>
          <Row
            className={`${styles.mailList} + ' ' + ${re.test(String(elem).toLowerCase()) ? styles.mailListValid : styles.mailListInvalid}`}
            gutter={[5, 0]}
          >
            <Col>{elem}</Col>
            <Col>
              <svg
                style={{ transform: 'translateY(10%)' }}
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={styles.mailListElementClose}
                onClick={() => {
                  mailList.splice(index, 1);
                  setMailList([...mailList]);
                }}
              >
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="15" y1="9" x2="9" y2="15"></line>
                <line x1="9" y1="9" x2="15" y2="15"></line>
              </svg>
            </Col>
          </Row>
        </span>
      );
    });
  };
  const mailAddressService = (value) => {
    setMailAddress(value);
    const mailAddressLenght = value.length;
    if (value[mailAddressLenght - 1] === ',' && mailAddressLenght !== 1) {
      mailList.push(value.substr(0, mailAddressLenght - 1));
      setMailList([...mailList]);
      setMailAddress('');
    }
  };
  return loading ? (
    <Spinner></Spinner>
  ) : (
    <>
      <fieldset disabled={!enableEdit}>
        <Form
          form={form}
          onFinish={onSubmit}
          style={{ backgroundColor: '#f7f8f9' }}
          onFinishFailed={({ errorFields }) => {
            //form.scrollToField(errorFields[0].name);
            form.scrollToField('int_number');
          }}
          initialValues={{
            due_date: moment(),
            cards: [
              {
                type: '1'
              }
            ]
          }}
        >
          <h1>Faktura</h1>

          <Row gutter={[40, 0]} style={{ justifyContent: 'space-around' }}>
            <Col xs={22} sm={11} className={styles.invoiceAndBuyerFormBoxes}>
              <h3 className={styles.invoiceHeader}>Faktura</h3>
              <h4 className={styles.invoiceSubHeader}>Unesite datum fakture i datum valutu</h4>

              <Row className={styles.formTwoSetFields}>
                <Col xs={10} sm={8}>
                  <span>Interni broj</span>
                </Col>
                <Col xs={10} sm={8} className={styles.inputAntdRow}>
                  <Form.Item name="int_number">
                    <Input className={styles.invoiceCustomInput} placeholder="automatski" autoComplete="off" />
                  </Form.Item>
                </Col>
              </Row>

              <Row className={styles.formTwoSetFields} justify="baseline">
                <Col xs={10} sm={8}>
                  <span>Broj fakture</span>
                </Col>
                <Col xs={10} sm={8} className={styles.inputAntdRow}>
                  <Form.Item name="number">
                    <Input
                      className={styles.invoiceCustomInput + ' ' + styles.invoicePlaceholderDisabled}
                      placeholder="automatski"
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className={styles.formTwoSetFields}>
                <Col xs={10} sm={8}>
                  <span>Broj profakture</span>
                </Col>
                <Col xs={10} sm={8} className={styles.inputAntdRow}>
                  <Form.Item name="pre_number">
                    <Input
                      className={styles.invoiceCustomInput + ' ' + styles.invoicePlaceholderDisabled}
                      placeholder="automatski"
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className={styles.formTwoSetFields}>
                <Col xs={10} sm={8}>
                  <span>Datum</span>
                </Col>
                <Col xs={10} sm={8} className={styles.inputAntdRow}>
                  <Form.Item name="date">
                    <Input
                      className={styles.invoiceCustomInput + ' ' + styles.invoicePlaceholderDisabled}
                      placeholder={currentDate}
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className={styles.formTwoSetFields}>
                <Col xs={10} sm={8}>
                  <span>Datum valute</span>
                </Col>
                <Col xs={10} sm={8} className={styles.inputAntdRow}>
                  <Form.Item
                    name="due_date"
                    className={styles.fullWidth}
                    rules={[
                      {
                        required: true,
                        message: 'Popunite datum!'
                      }
                    ]}
                  >
                    <DatePicker
                      className={styles.invoiceCustomInput}
                      format={dateFormat}
                      disabledDate={disabledDate}
                      placeholder="Izaberite datum"
                      disabled={!enableEdit}
                      showToday={false}
                      // renderExtraFooter={() => createElement('input', {
                      //                                                                 type: 'number',
                      //                                                                 onChange: val => {console.log('val - ', val);},
                      //                                                                 onClick: e => {e.target.focus(); console.log(e, 'awd');},
                      //                                                                 className: styles.invoiceCustomInput})
                      //                                                                }
                    ></DatePicker>
                  </Form.Item>
                </Col>
              </Row>
              <Row className={styles.formTwoSetFields}>
                <Col xs={10} sm={8}>
                  <span>Cijena bez PDV-a</span>
                </Col>
                <Col xs={10} sm={8} className={styles.inputAntdRow}>
                  <Form.Item name="sum_prices" /* TODO if 2 decimals needed -  normalize={value => parseFloat(value).toFixed(4)}*/>
                    <Input className={styles.invoiceCustomInput + ' ' + styles.invoicePlaceholderDisabled} disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row className={styles.formTwoSetFields}>
                <Col xs={10} sm={8}>
                  <span>Ukupan PDV</span>
                </Col>
                <Col xs={10} sm={8} className={styles.inputAntdRow}>
                  <Form.Item name="sum_taxes">
                    <Input className={styles.invoiceCustomInput + ' ' + styles.invoicePlaceholderDisabled} disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row className={styles.formTwoSetFields}>
                <Col xs={10} sm={8}>
                  <span>Ukupno</span>
                </Col>
                <Col xs={10} sm={8} className={styles.inputAntdRow}>
                  <Form.Item name="total">
                    <Input className={styles.invoiceCustomInput + ' ' + styles.invoicePlaceholderDisabled} disabled />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={22} sm={11} className={styles.invoiceAndBuyerFormBoxes}>
              <h3 className={styles.invoiceHeader}>Kupac</h3>
              <h4 className={styles.invoiceSubHeader}>Odaberite postojećeg ili unesite novog kupca</h4>
              <Row className={styles.formTwoSetFields}>
                <Col xs={10} sm={16} className={styles.inputAntdRow}>
                  <Form.Item
                    className={styles.labelForAddOrEditInvoice}
                    name="customer_name"
                    label="Naziv"
                    labelCol={{ span: 12 }}
                    rules={[
                      {
                        required: true,
                        message: 'Popunite naziv kupca!'
                      }
                    ]}
                  >
                    <span>{initialCustomerName}</span>
                    <AutoComplete
                      autocompleteValue={autocompleteValues.customer_name}
                      changeValue={onChangeCustomerNameValue}
                      autocompleteName="customer_name"
                      options={customers}
                      updateCustomerFields={autoCompleteUpdate}
                      initialValue={initialCustomerName}
                      field="name"
                      placeHolder="Unesite naziv"
                    ></AutoComplete>
                  </Form.Item>
                </Col>
              </Row>

              <Row className={styles.formTwoSetFields}>
                <Col xs={10} sm={16} className={styles.inputAntdRow}>
                  <Form.Item
                    className={styles.labelForAddOrEditInvoice}
                    initialValue=""
                    label="PIB"
                    labelCol={{ span: 12 }}
                    normalize={null}
                    name="customer_reg_no"
                    rules={[
                      {
                        required: reqReg,
                        message: requireMessagePib
                      },
                      {
                        validator(rule, value, callback) {
                          if ((form.getFieldValue('customer_country')==141 &&value || '').length > 0 && (value || '').length != 8 && (value || '').length != 13) {
                            callback('PIB mora sadržati 8 ili 13 cifara!');
                          }
                          callback();
                        }
                      }
                    ]}
                  >
                    <AutoComplete
                      options={customers}
                      autocompleteValue={autocompleteValues.customer_reg_no}
                      changeValue={onChangePibValue}
                      autocompleteName="customer_reg_no"
                      updateCustomerFields={autoCompleteUpdate}
                      initialValue={initialCustomerPib}
                      field="reg_no"
                      placeHolder="Unesite PIB"
                    ></AutoComplete>
                  </Form.Item>
                </Col>
              </Row>

              <Row className={styles.formTwoSetFields}>
                <Col xs={10} sm={16} className={styles.inputAntdRow}>
                  <Form.Item
                    className={styles.labelForAddOrEditInvoice}
                    label="PDV"
                    labelCol={{ span: 12 }}
                    name="customer_vat_no"
                    rules={[
                      {
                        required: reqVat,
                        message: 'Popunite PIB ili PDV!'
                      }
                      
                    ]}
                  >
                    <Input
                      onChange={(e) => {
                        updateRequiredFields();
                      }}
                      className={styles.invoiceCustomInput}
                      placeholder="Unesite PDV"
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row className={styles.formTwoSetFields}>
                <Col xs={10} sm={16} className={styles.inputAntdRow}>
                  <Form.Item className={styles.labelForAddOrEditInvoice} label="Adresa" labelCol={{ span: 12 }} name="customer_address">
                    <Input className={styles.invoiceCustomInput} placeholder="Unesite adresu" autoComplete="off" />
                  </Form.Item>
                </Col>
              </Row>

              <Row className={styles.formTwoSetFields}>
                <Col xs={10} sm={16} className={styles.inputAntdRow}>
                  <Form.Item className={styles.labelForAddOrEditInvoice} label="Grad" labelCol={{ span: 12 }} name="customer_city">
                    <Input className={styles.invoiceCustomInput} placeholder="Unesite grad" autoComplete="off" />
                  </Form.Item>
                </Col>
              </Row>

              <Row className={styles.formTwoSetFields}>
                <Col xs={10} sm={16} className={styles.inputAntdRow}>
                  <Form.Item
                    className={styles.labelForAddOrEditInvoice}
                    labelCol={{ span: 12 }}
                    label="Država"
                    name="customer_country"
                    initialValue={141}
                    rules={[
                      {
                        required: true,
                        message: 'Popunite polje!'
                      }
                    ]}
                  >
                    <Select 
                     onChange={(value) => {
                     
                     updateRequiredFields();
               }}
                      showSearch
                      className={styles.invoiceCustomInput}
                      dropdownClassName={countries ? styles.selectDropdownWithImg : styles.hideSelectDropdown}
                      optionFilterProp="data-searchvalue"
                      showArrow={false}
                      placeholder="Izaberite zemlju"
                      notFoundContent={'Nema rezultata'}
                      disabled={!enableEdit}
                      autoComplete="off"
                      
                    >
                      {getSelectOptionsWithOrWithoutImg(countries || [])}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </fieldset>
      <div className={styles.invoiceAndBuyerFormBoxes} style={{ margin: '40px 0' }}>
        <Row gutter={[40, 2]}>
          <Col span={24}>
            <h2>Stavke na fakturi</h2>
            <h4 className={styles.invoiceSubHeader}>Dodajte jednu ili više stavki kako bi završili proces kreiranja fakture</h4>
            <EditableTable
              items={items}
              setItems={setItems}
              registers={registers}
              enableEdit={(enableEdit && !isClosed) || (enableEdit || props.location.state)}
              ref={ref}
            ></EditableTable>
          </Col>
        </Row>
      </div>
      <div className={styles.invoiceAndBuyerFormBoxes} style={{ margin: '40px 0' }}>
        <Row gutter={[40, 2]}>
          <Col span={24}>
            <h2>Komentar</h2>
            <h4 className={styles.invoiceSubHeader}>Dodatna napomena</h4>
            <TextArea
              placeholder="Unesite komentar"
              rows={5}
              disabled= {!enableEdit}
              value={note}
              onChange={(e) => {
                setNote(e.target.value);
              }}
            />
          </Col>
        </Row>
      </div>
      <Row gutter={[40, 2]} justify="center" style={{ margin: '20px 0' }}>
        {(enableEdit && !isClosed && !isCanceled) || (enableEdit || props.location.state) ? (
          <>
            <Popover content="Odustani od dodavanja fakture" trigger="hover">
              <Button
                className={styles.cancelButton}
                onClick={() => {
                  confirmCancelInvoiceForm();
                }}
              >
                Odustani
              </Button>
            </Popover>

            <Popover content="Sačuvaj fakturu" trigger="hover">
              <Button type="primary" className={styles.addButton} onClick={saveInvoice} loading={buttonLoading}>
                Sačuvaj
              </Button>
            </Popover>
          </>
        ) : !isClosed && !isCanceled  ? (
          <>
            <Popover content="Započni uređivanje fakture" trigger="hover">
              <Button
                type="dashed"
                onClick={() => {
                  setEnableEdit(true);
                }}
              >
                Uredi
              </Button>
            </Popover>

            <Popover content="Pošalji fakturu na e-mail" trigger="hover">
              <Button
                type="primary"
                className={styles.addButton + ' ' + styles.sendButton}
                loading={buttonLoading}
                onClick={() => {
                  setIsModalVisible(true);
                }}
              >
                Pošalji
              </Button>
            </Popover>

            <Popover content="Fiskalizuj fakturu" trigger="hover">
              <Button type="primary" className={styles.addButton} onClick={fiscalize} loading={buttonLoading}>
                Fiskalizuj
              </Button>
            </Popover>
          </>
        ) : isNegative || isCanceled ?(
        <>
        <Popover content="Pošalji fakturu na e-mail" trigger="hover">
          <Button
            type="primary"
            className={styles.addButton + ' ' + styles.sendButton}
            loading={buttonLoading}
            onClick={() => {
              setIsModalVisible(true);
            }}
          >
            Pošalji
          </Button>
        </Popover>
        </>
        ) :
        (
          <>
            <Popover content="Pošalji fakturu na e-mail" trigger="hover">
              <Button
                type="primary"
                className={styles.addButton + ' ' + styles.sendButton}
                loading={buttonLoading}
                onClick={() => {
                  setIsModalVisible(true);
                }}
              >
                Pošalji
              </Button>
            </Popover>

            <Popover content="Storniraj fakturu" trigger="hover">
              <Button type="primary" className={styles.addButton} onClick={revokeInvoice} loading={buttonLoading} danger>
                Storniraj
              </Button>
            </Popover>
          </>
        )}
      </Row>
      <Modal
        title="Pošalji e-mail"
        visible={isModalVisible}
        onOk={() => {
          sendMail();
        }}
        onCancel={handleModalCancel}
        okText="Pošalji"
        cancelText="Odustani"
        okButtonProps={{ className: styles.addButton }}
        cancelButtonProps={{ className: styles.cancelButton }}
      >
        <div className={styles.mailRow}>
          {listEmailAddresses()}
          <input
            placeholder="Unesite e-mail adresu"
            className={styles.mailInput}
            value={mailAddress}
            onChange={(e) => {
              mailAddressService(e.target.value);
            }}
            autoComplete="off"
          />
        </div>
        <div className={styles.mailModalNote}>Napomena: E-mail adrese odvajajte zarezom</div>
      </Modal>
    </>
  );
};
