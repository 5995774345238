import React from 'react';
import { Table as AntTable, Menu, Pagination, Select, Row, Col } from 'antd';
import styles from '../../styles/Registers.module.css';
import { ActionsTableColCommon } from './ActionsTableColCommon';
import { Link } from 'react-router-dom';
import { Arrow } from '../../../components/Arrow';

export const Table = ({
  invoices,
  loading,
  totalRows,
  currentPage,
  pageSize,
  onPaginationChange,
  onPageSizeChange,
  onClone,
  onEdit,
  onDelete,
  orderValue,
  setOrderValue
}) => {
  const { Option } = Select;
  const currentStateFrom = currentPage !== 1 ? (currentPage - 1) * pageSize + 1 : 1;
  const currentStateTo = currentPage * pageSize < totalRows ? currentPage * pageSize : totalRows;
  var showing;
  if (totalRows) {
    showing =
      'Prikazano ' + String(currentStateFrom).replace(/^0+/, '') + '-' + String(currentStateTo).replace(/^0+/, '') + ' od ' + totalRows;
  } else {
    showing = '';
  }
  const orderingFunction = (columnKey) => {
    if (orderValue !== columnKey) {
      setOrderValue(columnKey);
    } else {
      setOrderValue('-' + columnKey);
    }
  };

  const renderTitle = (name, title) => {
    return (
      <Row>
        <Col xs={23} sm={23}>
          <span>{title}</span>
        </Col>

        <Col xs={1} sm={1}>
          {!orderValue.endsWith(name) ? (
            <Row style={{ marginTop: '-5px' }}>
              <Col xs={24} sm={24} style={{ height: '10px' }}>
                <Arrow rotate={true} color={'#bbbbc1'} />
              </Col>
              <Col xs={24} sm={24}>
                <Arrow rotate={false} color={'#bbbbc1'} />
              </Col>
            </Row>
          ) : orderValue === name ? (
            <Row>
              <Col xs={24} sm={24}>
                <Arrow rotate={false} color={'#1abc9c'} />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs={24} sm={24} style={{ height: '10px' }}>
                <Arrow rotate={true} color={'#1abc9c'} />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    );
  };

  const renderActions = (record) => {
    return <ActionsTableColCommon invoice={record} onClone={onClone} onEdit={onEdit} onDelete={onDelete} />;
  };

  const columns = [
    {
      title: renderTitle('id', '#'),
      key: 'id',
      dataIndex: 'id',
      fixed: 'left',
      onHeaderCell: (column) => {
        return {
          onClick: () => {
            orderingFunction(column.key);
          }
        };
      }
    },
    {
      title: 'Broj fakture',
      render: (record) => {
        return (
          <Link to={`/invoices/edit/${record.id}`}>{record.number ? 'Račun ' + record.number : 'Predračun ' + record.pre_number}</Link>
        );
      }
    },
    {
      title: renderTitle('customer_name', 'Kupac'),
      key: 'customer_name',
      dataIndex: 'customer_name',
      onHeaderCell: (column) => {
        return {
          onClick: () => {
            orderingFunction(column.key);
          }
        };
      }
    },
    {
      title: renderTitle('date', 'Datum'),
      key: 'date',
      dataIndex: ['date'],
      onHeaderCell: (column) => {
        return {
          onClick: () => {
            orderingFunction(column.key);
          }
        };
      },
      render: (date, invoice) => {
        var substring = String(date ? date : invoice.modified).substring(0, 16);
        return substring.split('T')[0] + ' ' + substring.split('T')[1];
      }
    },
    {
      title: renderTitle('total', 'Cijena bez pdv-a'),
      key: 'sum_prices',
      dataIndex: 'sum_prices',
      onHeaderCell: (column) => {
        return {
          onClick: () => {
            orderingFunction(column.key);
          }
        };
      },
      render: (record) => {
        return (
          parseFloat(record)
            .toFixed(4)
            .replace(/0{1,2}$/, '') + '€'
        );
      }
    },
    {
      title: renderTitle('total', 'Ukupna cijena'),
      key: 'total',
      dataIndex: 'total',
      onHeaderCell: (column) => {
        return {
          onClick: () => {
            orderingFunction(column.key);
          }
        };
      },
      render: (record) => {
        return (
          parseFloat(record)
            .toFixed(4)
            .replace(/0{1,2}$/, '') + '€'
        );
      }
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (record) => {
        if (record === 'open') {
          return 'profaktura';
        } else if (record === 'closed') {
          return 'faktura';
        } else {
          return 'stornirano';
        }
      }
    },
    {
      title: 'Akcije',
      key: 'action',
      fixed: 'right',
      width: '120',
      render: (record) => renderActions(record)
    }
  ];

  return (
    <div>
      <div className={styles.registersTable}>
        <AntTable
          columns={columns}
          dataSource={invoices}
          loading={loading}
          locale={{ emptyText: 'Nema rezultata' }}
          pagination={false}
          scroll={{ x: true }}
          rowKey={({ id }) => id}
        />
      </div>
      <div className={styles.tableFooter}>
        <div className={styles.paginationDesription}>{showing}</div>
        <div className={'ant-table-pagination ant-table-pagination-right ' + styles.paginationBackground}>
          <Pagination total={totalRows} current={currentPage} onChange={onPaginationChange} showSizeChanger={false} pageSize={pageSize} />
          <Select
            className={styles.pageSizeButton}
            defaultValue={String(pageSize)}
            style={{ width: 130, alignSelf: 'end' }}
            onChange={(e) => onPageSizeChange(e)}
          >
            <Option value="10">10 po strani</Option>
            <Option value="20">20 po strani</Option>
            <Option value="50">50 po strani</Option>
            <Option value="100">100 po strani</Option>
          </Select>
        </div>
      </div>
    </div>
  );
};
