import React, { useState, useEffect } from 'react';
import { buyersService } from '../../services/buyers';
import { Table } from './components/Table';
import { useDebounce, useFetch } from '../../utils/hooks';
import styles from '../styles/Registers.module.css';
import { Heading } from './components/Heading';
import { AddOrEditBuyer } from './components/AddOrEditBuyer';

export const Buyers = () => {
  const loadingSelectData = false;
  const [searchValue, setSearchValue] = useState('');
  const [orderValue, setOrderValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const size = localStorage.getItem('pageSizeBuyers')? localStorage.getItem('pageSizeBuyers') : 20;
  const [pageSize, setPageSize] = useState(size);
  const [visibleAddModal, setVisibleAddModal] = useState(false);
  const debouncedSearchValue = useDebounce(searchValue);
  const loadBuyers = (cancelToken) =>
    buyersService.getBuyersPaged({ currentPage, pageSize, searchValue: debouncedSearchValue, cancelToken, orderValue });

  const [
    {
      data: { results: buyers, count: totalRows },
      isFetching
    },
    getRegisters
  ] = useFetch(loadBuyers);

  const loading = isFetching || loadingSelectData;

  useEffect(() => {
    setCurrentPage(1);
    setOrderValue('');
  }, [debouncedSearchValue]);

  useEffect(() => {
    getRegisters();
  }, [debouncedSearchValue, pageSize, currentPage, getRegisters, orderValue]);
  return (
    <div className={styles.registers}>
      <h1>Kupci</h1>

      <div className={styles.invoiceAndBuyerFormBoxes}>
        <Heading
          searchValue={searchValue}
          onSearch={(search) => setSearchValue(search)}
          onCreateRegisterClicked={() => setVisibleAddModal(true)}
        />
        <Table
          buyers={buyers}
          loading={loading}
          totalRows={totalRows}
          currentPage={currentPage}
          pageSize={pageSize}
          onClone={getRegisters}
          onEdit={getRegisters}
          onDelete={getRegisters}
          orderValue={orderValue}
          setOrderValue={(order) => setOrderValue(order)}
          onPaginationChange={(page) => setCurrentPage(page)}
          onPageSizeChange={(size) => {
            setCurrentPage(1);
            setPageSize(size);
            localStorage.setItem('pageSizeBuyers', size);
          }}
        />
      </div>
      <AddOrEditBuyer visibleModal={visibleAddModal} onCloseModal={() => setVisibleAddModal(false)} onAdd={getRegisters} />
    </div>
  );
};
