import React, { useState } from 'react';
import { useEffect } from 'react';
import { CompaniesAndCountriesService } from '../services/companiesAndCountries';
export const CompaniesAndCountriesContext = React.createContext({});

export const CompaniesAndCountriesContextProvider = (props) => {
  const [companies, setCompanies] = useState();
  //const [invoiceTemplates, setInvoiceTemmplates] = useState();
  const [countries, setCountries] = useState();
  const getCompanies = async () => {
    const companiesObj = await CompaniesAndCountriesService.getCompanies();
    setCompanies(companiesObj);
  };
  /*
  const getInvoiceTemplates =async () =>{
    const receiptTemmplatesArr= await CompaniesAndCountriesService.getInvoiceTemplates();
    setInvoiceTemmplates([...receiptTemmplatesArr]);

  };
*/
  const getCountries = async () => {
    const countriesObj = await CompaniesAndCountriesService.getCountries();
    setCountries(countriesObj);
  };

  const refresh = () => {
    getCompanies();
    getCountries();
    //getInvoiceTemplates();

  };
  const refreshCompanie = () => {
    getCompanies();

  };
  useEffect(() => {
    getCompanies();
    getCountries();
    //getInvoiceTemplates();
  }, []);

  return (
    <CompaniesAndCountriesContext.Provider value={{ companies, countries, refresh,refreshCompanie }}>
      {props.children}
    </CompaniesAndCountriesContext.Provider>
  );
};
