import React, { useState, useEffect } from 'react';
import { Table } from './components/Table';
import { useDebounce, useFetch } from '../../utils/hooks';
import styles from '../styles/Registers.module.css';
import { Heading } from './components/Heading';
import { invoicesService } from '../../services/invoices';

export const Invoices = () => {
  const [loadingSelectData, selLoadingSelectData] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const size = localStorage.getItem('pageSizeInvoices') ? localStorage.getItem('pageSizeInvoices') : 20;
  const [pageSize, setPageSize] = useState(size);
  const [selectorValue, setSelectorValue] = useState('');
  const [visibleAddModal, setVisibleAddModal] = useState(false);
  const debouncedSearchValue = useDebounce(searchValue);
  const debouncedSelectorValue = useDebounce(selectorValue);
  const [orderValue, setOrderValue] = useState('');

  const loadInvoices = (cancelToken) =>
    invoicesService.getReceiptsPaged({
      currentPage,
      pageSize,
      searchValue: debouncedSearchValue,
      selectorValue: debouncedSelectorValue,
      cancelToken,
      orderValue
    });
  const [
    {
      data: { results: invoices, count: totalRows },
      isFetching
    },
    getInvoices
  ] = useFetch(loadInvoices);

  const loading = isFetching || loadingSelectData;

  useEffect(() => {
    setCurrentPage(1);
    setOrderValue('');
  }, [debouncedSearchValue]);

  useEffect(() => {
    getInvoices();
  }, [debouncedSearchValue, debouncedSelectorValue, pageSize, currentPage, getInvoices, orderValue]);
  return (
    <div className={styles.registers}>
      <h1>Fakture</h1>
      <div className={styles.invoiceAndBuyerFormBoxes}>
        <Heading
          searchValue={searchValue}
          selectorValue={selectorValue}
          onChangeSelector={(value) => setSelectorValue(value)}
          onSearch={(search) => setSearchValue(search)}
          onCreateRegisterClicked={() => setVisibleAddModal(true)}
        />
        <Table
          invoices={invoices}
          loading={loading}
          totalRows={totalRows}
          currentPage={currentPage}
          pageSize={pageSize}
          onClone={getInvoices}
          onEdit={getInvoices}
          onDelete={getInvoices}
          orderValue={orderValue}
          setOrderValue={(order) => setOrderValue(order)}
          onPaginationChange={(page) => setCurrentPage(page)}
          onPageSizeChange={(size) => {
            setCurrentPage(1);
            setPageSize(size);
            localStorage.setItem('pageSizeInvoices', size);
          }}
        />
      </div>
      {/* <AddOrEditBuyer visibleModal={visibleAddModal} onCloseModal={() => setVisibleAddModal(false)} onAdd={getInvoices} /> */}
    </div>
  );
};
