import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { AutoComplete as AntAutoComplete } from 'antd';
import styles from '../../styles/Registers.module.css';

export const AutoComplete = (props) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
  // if (props.itemAutocomplete) {
  //   const options = props.options.map((option) => {
  //     const obj = { ...option };
  //     obj['value'] = option.name + ' [' + option.code + ']';
  //     return obj;
  //   });
  //   setOptions(options);
  // } else {
    const options = props.options.map((option) => {
      const obj = { ...option };
      obj['value'] = option[props.field];
      return obj;
    });
    setOptions(options);
  // }
  }, [props]);
  const onNameSelect = (value, option) => {
    let obj;
    if (props.itemAutocomplete) {
      const { name, unit, unit_price, tax, code } = option;
      obj = { name, unit, unit_price, tax, code };
    } else {
      obj = {
        customer_name: props.field === 'name' ? value : option.name,
        customer_reg_no: props.field !== 'name' ? value : option.reg_no,
        customer_vat_no: option.vat_no,
        customer_address: option.address,
        customer_postal_code: option.postal_code,
        customer_city: option.city,
        customer_country: option.country
      };
    }

    props.updateCustomerFields(obj);
  };
  return (
    <div>
      <AntAutoComplete
        onSelect={onNameSelect}
        options={options || []}
        onChange={(value) => props.changeValue(props.autocompleteName, value)}
        value={props.autocompleteValue}
        className={!props.itemAutocomplete ? styles.invoiceCustomInput : ''}
        placeholder={props.placeHolder}
        autoFocus={props.focus}
        // onKeyUp={(value) => {
        //   props.field === 'name'
        //     ? props.updateCustomerFields({ customer_name: value.target.defaultValue })
        //     : props.updateCustomerFields({ customer_reg_no: value.target.defaultValue });
        // }}
        filterOption={(inputValue, option) => {
          return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
        }}
      />
    </div>
  );
};
