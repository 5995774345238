import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { Modal, Button, Popover, Form, Input, Row, Col, Select, InputNumber } from 'antd';
import { registersService } from '../../../services/registers';
import { openErrorNotification, openSuccessNotification } from '../../../utils/notifications';

import styles from '../../styles/Registers.module.css';
import { showApiErrors } from '../../../utils/showApiErrors';

const { Option } = Select;

export const AddOrEditRegister = ({ visibleModal, onCloseModal, onClone, onEdit, onAdd, register, isClone, fromInvoice }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [registers, setRegisters] = useState([]);
  // when register variable is changed initialValues function is caled
  const initialValues = useMemo(() => {
    if (!register) {
      return { tax: '21' };
    }
    const name = isClone ? register.name + ' - klon' : register.name;
    const { unit, code } = register;
    var unit_price;
    if (typeof register.unit_price === 'string') {
      unit_price = parseFloat(register.unit_price.substring(0, register.unit_price.length - 1))
        .toFixed(4)
        .replace(/0{1,2}$/, '');
    } else {
      unit_price = register.unit_price;
    }
    var tax;

    if (typeof register.tax === 'string' && isNaN(register.tax)) {
      if (register.tax[register.tax.length - 1] === '%') {
        tax = register.tax.substring(0, register.tax.length - 1);
      } else {
        tax = register.tax;
      }
    } else {
      tax = register.ex_tax;
    }
    return { name, unit, unit_price, tax, code };
  }, [register, isClone]);

  useEffect(() => {
    async function getRegisters() {
      setLoading(true);
      try {
        const { results } = await registersService.getAllRegisters();
        setRegisters(results);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setLoading(false);
      }
    }
    getRegisters();
  }, []);

  const resetFields = useCallback(() => {
    form.resetFields();
  }, [form]);

  useEffect(() => {
    if (visibleModal) {
      resetFields();
    }
  }, [resetFields, visibleModal]);

  const onSubmit = async (values) => {
    setLoading(true);
    if (register && !isClone && !fromInvoice) {
      try {
        await registersService.editRegister(register.id, values);
        onCloseModal();
        openSuccessNotification({ message: 'Uspješno uređeno!' });
        await onEdit();
      } catch (e) {
        showApiErrors(e);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        await registersService.addRegister(values);
        onCloseModal();
        openSuccessNotification({ message: `Šifarnik uspješno ${isClone ? 'kloniran' : 'kreiran'}!` });
        if (!fromInvoice) {
          isClone ? await onClone() : await onAdd();
        }
      } catch (e) {
        console.dir(e);

        showApiErrors(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const type = register && isClone ? 'Kloniraj' : register && !fromInvoice ? 'Uredi' : 'Dodaj';

  return (
    <Modal
      title={type + ' stavku'}
      visible={visibleModal}
      wrapClassName={styles.addOrEditRegisterModal}
      onCancel={() => {
        onCloseModal();
      }}
      footer={[
        <Popover
          content={'Odustani od ' + (register && isClone ? 'kloniranja' : register ? 'uređivanja' : 'dodavanja') + ' u šifarnik'}
          trigger="hover"
        >
          <Button
            key="cancel"
            className={styles.cancelButton}
            onClick={() => {
              onCloseModal();
            }}
          >
            Odustani
          </Button>
        </Popover>,

        <Popover content={(register && isClone ? 'Kloniraj' : register ? 'Uredi' : 'Sačuvaj') + ' u šifarnik'} trigger="hover">
          <Button key="submit" type="primary" loading={loading} onClick={() => form.submit()} className={styles.addButton}>
            Sačuvaj
          </Button>
        </Popover>
      ]}
      width={600}
    >
      <div className={styles.modalFormWrapper}>
        <Form
          form={form}
          initialValues={initialValues}
          onFinish={onSubmit}
          onFinishFailed={({ errorFields }) => {
            form.scrollToField(errorFields[0].name);
          }}
        >
          <div className={styles.nameAndCheckboxFieldsWrapper}>
            <Row gutter={[16, 2]}>
              <Col xs={24} sm={12}>
                <Form.Item
                label="Naziv"
                labelCol={{ span: 24 }}
                className={styles.labelForAddOrEditBuyer}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: 'Unesite naziv'
                    }, 
                    {
                      max: 50,
                      message: 'Dozvoljeno 50 karaktera!'
                    }

                  ]}
                >
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label="Jedinica mjere"
                  labelCol={{ span: 24 }}
                  className={styles.labelForAddOrEditBuyer}
                  name="unit"
                  normalize={(value) => (value || '').replace(/[^A-Za-z0-9 ^]+/g, '')}
                  rules={[
                    {
                      required: true,
                      message: 'Unesite jedinicu mjere!'
                    }
                  ]}
                >
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label="Šifra"
                  labelCol={{ span: 24 }}
                  className={styles.labelForAddOrEditBuyer}
                  name="code"
                  rules={[
                    {
                      required: true,
                      message: 'Unesite šifru!'
                    },
                    {
                      validator(rule, value, callback) {
                        if (registers.find((c) => c.code === String(value)) && !(register && !isClone && String(value) === register.code)) {
                          callback('šifra već postoji u šifarniku!');
                        }
                        callback();
                      }
                    }
                  ]}
                >
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                label="Cijena"
                labelCol={{ span: 24 }}
                className={styles.labelForAddOrEditBuyer}
                  name="unit_price"
                  rules={[
                    {
                      required: true,
                      message: 'Unesite cijenu!'
                    }
                  ]}
                >
                  <InputNumber
                    formatter={(value) =>
                      value
                        .toString()
                        .replace(',', '.')
                        .replace(/[^0-9.]/g, '')
                    }
                    parser={(input) => input.slice(0, input.indexOf('.') > 0 ? input.indexOf('.') + 5 : input.length)}
                    min="0"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="tax"
                  label="Porez"
                  labelCol={{ span: 24 }}
                  className={styles.labelForAddOrEditBuyer}
                  rules={[
                    {
                      required: true,
                      message: 'Unesite porez!'
                    }
                  ]}
                >
                  <Select>
                    <Option value="21">21%</Option>
                    <Option value="7">7%</Option>
                    <Option value="0">0%</Option>
                    <Option disabled className={styles.cursorPointer}>
                      Oslobođenja
                    </Option>
                    <Option value="VAT_CL17" title="Mjesto prometa usluga">
                      VAT_CL17
                    </Option>
                    <Option value="VAT_CL20" title="Poreska osnovica i ispravka poreske osnovice">
                      VAT_CL20
                    </Option>
                    <Option value="VAT_CL26" title="Oslobođenja od javnog interesa">
                      VAT_CL26
                    </Option>
                    <Option value="VAT_CL27" title="Ostala oslobođenja">
                      VAT_CL27
                    </Option>
                    <Option value="VAT_CL28" title="Oslobođenja kod uvoza proizvoda">
                      VAT_CL28
                    </Option>
                    <Option value="VAT_CL29" title="Oslobođenja kod privremenog uvoza proizvoda">
                      VAT_CL29
                    </Option>
                    <Option value="VAT_CL30" title="Posebna oslobođenja">
                      VAT_CL30
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
