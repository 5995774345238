import { callApi } from './api';
import { getQueryString, getOffsetLimitFromPagination } from '../utils/query';

class RegistersService {
  async getRegistersPaged({ currentPage, pageSize, searchValue, cancelToken, orderValue }) {
    if (orderValue !== '') {
      searchValue = '';
      currentPage = 1;
    }
    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      search: searchValue,
      ordering: orderValue ? orderValue : 'code'
    });
    const result = await callApi({ url: `/api/registers/${query}`, cancelToken });
    if (result.results) {
      result.results.map((elem) => {
        if (!elem.tax) {
          elem.tax = elem.ex_tax;
        }
        return elem;
      });
    }
    return result;
  }

  async addRegister(data) {
    if (isNaN(data.tax)) {
      data.ex_tax = data.tax;
      data.tax = null;
    }
    return await callApi({
      url: '/api/registers/',
      method: 'POST',
      data
    });
  }

  async getAllRegisters() {
    const result = await callApi({
      url: '/api/registers?limit=2000',
      method: 'GET'
    });
    if (result.results) {
      result.results.map((elem) => {
        if (!elem.tax) {
          elem.tax = elem.ex_tax;
        }
        return elem;
      });
    }
    return result;
  }

  async editRegister(id, data) {
    if (isNaN(data.tax)) {
      data.ex_tax = data.tax;
      data.tax = null;
    }
    return await callApi({
      url: `/api/registers/${id}/`,
      method: 'PATCH',
      data
    });
  }

  async deleteRegister(id) {
    return await callApi({
      url: `/api/registers/${id}/`,
      method: 'DELETE'
    });
  }
}

export const registersService = new RegistersService();
