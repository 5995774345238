import React, { useState } from 'react';
import { Layout, Row, Col, Divider, Dropdown, Menu } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { auth } from '../../services/auth';
import { DownOutlined } from '@ant-design/icons';
import { useContext } from 'react';
import { UserContext } from '../../contexts/userContext';

export const Header = () => {
  const { userInfo } = useContext(UserContext);
  const [fullScreen, setFullScreen] = useState(false);
  const toggleFullScreen = () => {
    var doc = window.document;
    var docEl = doc.documentElement;

    var requestFullScreen =
      docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
    var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

    if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
      requestFullScreen.call(docEl);
    } else {
      cancelFullScreen.call(doc);
    }
  };
  return (
    <Layout.Header style={{ padding: 0, height: true ? 'auto' : '64px' }}>
      <Row style={{ flexWrap: 'nowrap', maxHeight: '100%' }}>
        <Col style={{ width: '70%', marginRight: '15px' }}></Col>
        <Col className="header-right">
          <div
            onClick={() => {
              setFullScreen(!fullScreen);
              toggleFullScreen();
            }}
            style={{ marginRight: '8px', marginTop: '3px' }}
          >
            {!fullScreen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 22 22"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"></path>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 22 22"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3"></path>
              </svg>
            )}
          </div>
          <Divider type="vertical" style={{ fontSize: '35px', borderColor: '#c3cad2' }} />

          <Dropdown
            overlayStyle={{ width: '200px' }}
            overlayClassName="header-dropdown"
            overlay={
              <Menu>
                <Menu.Item key="3" onClick={() => auth.logout()}>
                  Odjavi se
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <span className="header-right-user">
              <span className="header-username">
                {userInfo.first_name ? `${userInfo.first_name} ${userInfo.last_name}` : <LoadingOutlined spin />}
              </span>
              <DownOutlined className="down-arrow" />{' '}
              {userInfo.first_name ? <img className="profile-avatar" src={userInfo.image} alt="avatar" /> : null}
            </span>
          </Dropdown>
        </Col>
      </Row>
    </Layout.Header>
  );
};
