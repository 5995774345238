import { callApi } from './api';
import { getQueryString, getOffsetLimitFromPagination } from '../utils/query';

class BuyersService {
  async getBuyersPaged({ currentPage, pageSize, searchValue, cancelToken, orderValue }) {
    if (orderValue !== '') {
      searchValue = '';
      currentPage = 1;
    }
    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      search: searchValue,
      ordering: orderValue ? orderValue : 'name'
    });
    return await callApi({ url: `/api/customers${query}`, cancelToken });
  }

  async getAllBuyers() {
    return await callApi({
      url: '/api/customers?limit=1000',
      method: 'GET'
    });
  }

  async addBuyer(data) {
    return await callApi({
      url: '/api/customers/',
      method: 'POST',
      data
    });
  }

  async editBuyer(id, data) {
    return await callApi({
      url: `/api/customers/${id}/`,
      method: 'PATCH',
      data
    });
  }

  async deleteBuyer(id) {
    return await callApi({
      url: `/api/customers/${id}/`,
      method: 'DELETE'
    });
  }
}

export const buyersService = new BuyersService();
