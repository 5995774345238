import React from 'react';
import { Input, Button, Popover, Select } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';

import styles from '../../styles/Registers.module.css';
import { Link } from 'react-router-dom';

const {Option} = Select;

export const Heading = ({ searchValue, selectorValue, onSearch, onCreateRegisterClicked, onChangeSelector }) => {
  return (
    <div className={styles.headingActions}>
      <div className={styles.searchInput}>
        <Input placeholder="Pretraži" value={searchValue} suffix={<SearchOutlined />} onChange={(e) => onSearch(e.target.value)} />
        <Select style={{ margin: '0 5px', width: 140, alignSelf: 'end'}} value={selectorValue} onChange={(e) => onChangeSelector(e)}>
          <Option value="">Sve</Option>
          <Option value="closed">Fakture</Option>
          <Option value="open">Profakture</Option>
          <Option value="canceled">Stornirano</Option>
        </Select>
      </div>

      <div>
        <Popover content="Dodaj fakturu" trigger="hover">
          <Link to="/invoices/add">
           {' '}
           <Button type="primary" className={styles.addButton} onClick={onCreateRegisterClicked}>
              <PlusOutlined />
              Dodaj
            </Button>
          </Link>
        </Popover>
      </div>
    </div>
  );
};
