import React from 'react';
import { Input, Button } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Modal, Popover } from 'antd';

import styles from '../../styles/Registers.module.css';

export const Heading = ({ searchValue, onSearch, onCreateRegisterClicked }) => {
  return (
    <div className={styles.headingActions}>
      <div className={styles.searchInput}>
        <Input placeholder="Pretraži" value={searchValue} suffix={<SearchOutlined />} onChange={(e) => onSearch(e.target.value)} />
      </div>

      <Popover content="Dodaj kupca" trigger="hover">
       <div>
         <Button type="primary" title="Dodaj kupca" className={styles.addButton} onClick={onCreateRegisterClicked}>
           <PlusOutlined />
           Dodaj
         </Button>
        </div>
      </Popover>
      
    </div>
  );
};
