import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { getAllowedRoutes } from '../../routes';
import { Logo } from '../../components/Logo';

const generateSidebarMenu = (routes, parentPath = '') => {
  return routes.map((route) => {
    if (route.skipRender) {
      return null;
    }
    const path = route.linkPath || route.path;

    if (route.children) {
      return (
        <Menu.SubMenu
          key={path}
          title={
            <span>
              {route.icon}
              <span>{route.title}</span>
            </span>
          }
        >
          {generateSidebarMenu(route.children, path)}
        </Menu.SubMenu>
      );
    }

    const fullPath = parentPath + typeof path !== 'string' ? path[1] : path;
    return (
      <Menu.Item key={fullPath}>
        <Link to={fullPath}>
          {!parentPath && route.icon}
          <span>{route.title}</span>
        </Link>
      </Menu.Item>
    );
  });
};

export const Sidebar = ({ isSmallScreen, currentPage }) => {
  const wasCollapsed = localStorage.getItem('collapse');
  const [collapsed, setCollapsed] = useState(wasCollapsed ? wasCollapsed === 'true' : window.innerWidth < 1400);

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
    localStorage.setItem('collapse', collapsed);
  };

  const curPage = currentPage === '/' ? '/invoices' : currentPage;
  //const additionalProps = isSmallScreen ? {} : { breakpoint: 'lg', collapsedWidth: 0 };
  const additionalProps = {};
  return (
    <Layout.Sider collapsible collapsed={collapsed} onCollapse={onCollapse} {...additionalProps}>
      <Link to="/">
        <Logo />
      </Link>

      <Menu theme="dark" mode="inline" id="menu" selectedKeys={curPage}>
        {generateSidebarMenu(getAllowedRoutes())}
      </Menu>
    </Layout.Sider>
  );
};
