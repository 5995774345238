import React, { useState } from 'react';
import { Tabs } from 'antd';
import { openSuccessNotification } from '../../utils/notifications';
import { userProfileService } from '../../services/userProfile';
import { PersonalDetails } from './components/PersonalDetails';
import { CompanyDetails } from './components/CompanyDetails';
import { CompaniesAndCountriesService } from '../../services/companiesAndCountries';

import { Security } from './components/Security';
import { Spinner } from '../../components/Spinner';
import { useContext } from 'react';
import { CompaniesAndCountriesContext } from '../../contexts/companiesAndCountriesContext';

import { UserContext } from '../../contexts/userContext';
import { showApiErrors } from '../../utils/showApiErrors';

const { TabPane } = Tabs;
export const UserProfile = () => {
  const { refreshCompanie, companies,invoiceTemplates } = useContext(CompaniesAndCountriesContext);
  const { refresh, userInfo } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const updateUser = async (values) => {
    setLoading(true);
    try {
      await userProfileService.editUserDetails(values, userInfo.id);
      openSuccessNotification({ message: 'Uspješno sačuvano' });
      refresh();
    } catch (e) {
      showApiErrors(e);
    } finally {
      setLoading(false);
    }
  };
  const updateUserPassword = async (values) => {
    setLoading(true);
    try {
      await userProfileService.changePassword(values, userInfo.id);
      openSuccessNotification({ message: 'Uspješno sačuvano' });
    } catch (e) {
      showApiErrors(e);
    } finally {
      setLoading(false);
    }
  };
  const updateCompany = async (values) => {
    setLoading(true);
    try {
      await CompaniesAndCountriesService.editCompanyDetails(values, companies[0].id);
      openSuccessNotification({ message: 'Uspješno sačuvano' });
      refreshCompanie();
    } catch (e) {
      showApiErrors(e);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      {loading ? (
        <Spinner></Spinner>
      ) : (
        <>
          <h2>Profil</h2>
          <Tabs>
            <TabPane tab="Lični podaci" key="2">
              <PersonalDetails initialData={userInfo} updateUser={updateUser}></PersonalDetails>
            </TabPane>
            <TabPane tab="Podaci firme" key="1">
            <CompanyDetails invoiceTemplates ={invoiceTemplates} initialData={companies ? companies[0] : {}} updateCompany={updateCompany}></CompanyDetails>
            </TabPane>
            <TabPane tab="Sigurnost" key="3">
              <Security updateUserPassword={updateUserPassword}></Security>
            </TabPane>
          </Tabs>
        </>
      )}
    </div>
  );
};
