import { callApi } from './api';

class CompaniesAndCountriesServiceClass {
  async getCompanies() {
    return await callApi({ url: '/api/companies' });
  }
  async getCountries() {
    return await callApi({ url: '/api/countries?fields=id,name,img' });
  }

  async getInvoiceTemplates() {
    return await callApi({ url: '/api/invoice_templates/?fields=id,name' });
  }
  async editCompanyDetails(data, id) {
    return await callApi({
      url: `api/companies/${id}/`,
      method: 'PATCH',
      data: data
    });
  }
}

export const CompaniesAndCountriesService = new CompaniesAndCountriesServiceClass();
