import React, { useEffect, useState } from 'react';
import { Form,Upload, Input, Select, Button, Spin, Col,Row,DatePicker } from 'antd';
import { getSelectOptionsWithOrWithoutImg } from '../../../utils/options';
import { UserOutlined } from '@ant-design/icons';
import styles from '../Profile.module.css';
import { openErrorNotification } from '../../../utils/notifications';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import moment from 'moment';
import { useContext } from 'react';
import { CompaniesAndCountriesContext } from '../../../contexts/companiesAndCountriesContext';

const dateFormat = 'DD-MM-YYYY';
export const PersonalDetails = ({ initialData, updateUser }) => {
  const { companies, countries } = useContext(CompaniesAndCountriesContext);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [selectedImageUrl, setselectedImageUrl] = useState();

  useEffect(() => {
    const { email, image, first_name, last_name, city, country, company, phone_number, username } = initialData;
    if(image){
      setselectedImageUrl(image);
    }
    const birth_date = initialData['birth_date'] ? moment(new Date(initialData['birth_date'])) : null;
    form.setFieldsValue({
      email,
      image,
      first_name,
      last_name,
      city,
      country,
      company,
      phone_number,
      username,
      birth_date
    });
  }, [form, initialData]);
  const onSubmit = async (values) => {
    values.image = selectedImage;
    setLoading(true);
    await updateUser(values);
    setLoading(false);
  };
  const disabledDate = (current) => {
    const valide = moment().diff(current, 'years', true);
    return valide <= 18 || valide > 100;
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };





  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const handleImageRemove = (file)=>{ 
    setSelectedImage(null);

  };
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };
  const handleImageChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(false);
      return;
    }
    if (info.file.status === 'done') {


      getBase64(info.file.originFileObj, imageUrl =>
        {
        setSelectedImage(info.file.originFileObj);
        setselectedImageUrl(imageUrl);
        setLoading(false);
        }
      );
    }
  };
  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      openErrorNotification({message:'File mora biti JPG/PNG!',duration:8});
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      openErrorNotification({message:'Logo mora biti manji od 2M!',duration:8});
    }
    return isJpgOrPng && isLt2M;
  }




  return (
    <div className={styles.personalDetailsContent}>
      <h2>
        <UserOutlined /> Uredi lične podatke
      </h2>
      <Spin spinning={loading} wrapperClassName={styles.companyDetailsSpin}>
        <Form {...layout}
          form={form}
          name="userForm"
          onFinish={onSubmit}
          onFinishFailed={({ errorFields }) => {
            form.scrollToField(errorFields[0].name);
          }}
        >
          <div className={styles.companyDetailsContainer}>

          <div>
              <Upload
        name="avatar"
        listType="picture-card"
        className={styles.avatarUplouder}
        showUploadList={false}
        customRequest={dummyRequest}
        beforeUpload={beforeUpload}
        onRemove= {handleImageRemove}
        onChange={handleImageChange}
      >
        {selectedImageUrl ? <img src={selectedImageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
    </Upload>
    </div>




    <div className={styles.companyDetailsForm}>
              <Row>
              <Col className={styles.gutterBox}
              span={12} >
              <Form.Item
                label="Ime"
                name="first_name"
              >
                <Input
                  placeholder="Ime"
                  disabled
                />
              </Form.Item>
                </Col>
                <Col className={styles.gutterBox} span={12} >
  

              <Form.Item
                label="Prezime"
                name="last_name"
                className={styles.fullWidth}
              >
                <Input
                  placeholder="Prezime"
                  disabled
                />
              </Form.Item>

              </Col>
              </Row>
              <Row>
              <Col className={styles.gutterBox} span={12} >
  
              <Form.Item
                label="Kompanija"
                name="company"
                className={styles.fullWidth}
              >
                <Select
                  showSearch
                  dropdownClassName={companies ? styles.selectDropdownWithImg : styles.hideSelectDropdown}
                  optionFilterProp="data-searchvalue"
                  showArrow={false}
                  placeholder="Kompanija"
                  disabled
                >
                  {getSelectOptionsWithOrWithoutImg(companies || [], 0)}
                </Select>
              </Form.Item>
              </Col>
              <Col span={12} className={styles.gutterBox}>
              <Form.Item
                  label="Korisničko ime"
                  name="username"
                  className={styles.fullWidth}
                >
                  <Input
                    placeholder="Korisničko ime"
                    disabled
                  />
                </Form.Item>

              </Col>
              </Row>
              <Row>
              <Col span={12} className={styles.gutterBox}>
              <Form.Item
                label="e-mail"
                name="email"
                rules={[
                  {
                    validator: async (rule, value) => {
                      if (value.length > 70) {
                        throw new Error('Dozvoljeno 70 karaktera!');
                      }
                    }
                  },
                  {
                    required: true,
                    message: 'Popunite polje!'
                  }
                ]}
              >
                <Input placeholder="Email" maxLength={70} />
              </Form.Item>

              </Col>
              <Col span={12} className={styles.gutterBox}>
              <Form.Item
                label="Broj telefona"
                name="phone_number"
                rules={[
                  {
                    required: true,
                    message: 'Popunite polje!'
                  }
                ]}
              >
                <Input placeholder="Broj telefona" maxLength={60} />
              </Form.Item>

              </Col>
              </Row>
              <Row>
              <Col span={12} className={styles.gutterBox}>
              <Form.Item label="Datum rođenja" name="birth_date" className={styles.fullWidth}>
                <DatePicker
                  className={styles.datePickerItem}
                  format={dateFormat}
                  disabledDate={disabledDate}
                  placeholder="Izaberite datum"
                ></DatePicker>
              </Form.Item>
                </Col>
              <Col span={12} className={styles.gutterBox}>
              <Form.Item
                label="Grad"
                name="city"
                rules={[
                  {
                    validator: async (rule, value) => {
                      if (value.length > 60) {
                        throw new Error('Dozvoljeno 60 karaktera!');
                      }
                    }
                  },
                  {
                    required: true,
                    message: 'Popunite polje!'
                  }
                ]}
              >
                <Input placeholder="Grad" maxLength={60} />
              </Form.Item>
                </Col>
              </Row>
              <Row>
              <Col span={12} className={styles.gutterBox}>
              <Form.Item
                label="Država"
                name="country"
                className={styles.fullWidth}
                rules={[
                  {
                    required: true,
                    message: 'Popunite polje!'
                  }
                ]}
              >
                <Select
                  showSearch
                  dropdownClassName={countries ? styles.selectDropdownWithImg : styles.hideSelectDropdown}
                  optionFilterProp="data-searchvalue"
                  showArrow={false}
                  placeholder="Izaberite zemlju"
                  notFoundContent={'Nema rezultata'}
                >
                  {getSelectOptionsWithOrWithoutImg(countries || [])}
                </Select>
              </Form.Item>
                </Col>
              <Col span={12} className={styles.gutterBox}>
              <Form.Item className={styles.ConfirmInfoChangesItem}>
                <Button className={styles.confirmInfoChangesBtn} type="primary" htmlType="submit">
                  Sačuvaj izmjene
                </Button>
              </Form.Item>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      </Spin>
    </div>
  );
};
