import './layout.css';

import React from 'react';
import { Layout } from 'antd';
import Media from 'react-media';

import { Header } from './components/Header';
import { Sidebar } from './components/Sidebar';
import { SwitchRoutes } from '../routes/SwitchRoutes';
import { getAllowedRoutes } from '../routes';
import { useLocation } from 'react-router-dom';
import { isHeaderAndSidebarHidden } from './utils/hiddenSidebar';

export const AppLayout = () => {
  const location = useLocation();
  const isSidebarVisible = !isHeaderAndSidebarHidden(location);

  return (
    <Layout style={{ minHeight: '100vh', zIndex: '2', backgroundColor: '#f7f8f9' }}>
      {isSidebarVisible && (
        <Media query="(max-width: 599px)">{(matches) => <Sidebar isSmallScreen={matches} currentPage={location.pathname} />}</Media>
      )}
      <Layout>
        {isSidebarVisible && <Header />}
        <Layout.Content>
          <div style={{ padding: isSidebarVisible ? 24 : 0, background: '#f7f8f9', minHeight: '100vh' }}>
            <SwitchRoutes routes={getAllowedRoutes()} />
          </div>
        </Layout.Content>
      </Layout>
    </Layout>
  );
};
