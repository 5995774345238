import { openErrorNotification } from './notifications';

export const showApiErrors = (error) => {
  let shouldDisplayStatusCode = false;
  let message = '';
  if (error && error.response) {
    shouldDisplayStatusCode = true;
    if (error.response.data) {
      if (error.response.data.code === 'validation_failed') {
        const details = error.response.data.detail;
        Object.keys(details).forEach(function (key) {
          message += `\n ${key} - ${details[key].join(',')}`;
        });
      } else {
        const additionalMessage =
          error.response.data.detail && error.response.data.detail.code ? ' ' + error.response.data.detail.code[0] : '';
        message = error.response.data.message + additionalMessage;
      }
    }
  }
  openErrorNotification({
    message: shouldDisplayStatusCode ? `Request failed with status code ${error.response.status} \n ${message}` : 'Something went wrong',
    duration: 8
  });
};
