import { callApi } from './api';
import { getQueryString, getOffsetLimitFromPagination } from '../utils/query';

class InvoicesService {
  async getReceiptsPaged({ currentPage, pageSize, searchValue, selectorValue, cancelToken, orderValue }) {
    if (orderValue !== '') {
      searchValue = '';
      currentPage = 1;
    }
    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      search: searchValue,
      status: selectorValue,
      ordering: orderValue ? orderValue : '-modified'
    });
    return await callApi({ url: `/api/invoices${query}`, cancelToken });
  }

  async addReceipts(data) {
    return await callApi({
      url: '/api/invoice/new/',
      method: 'POST',
      data
    });
  }

  async editReceipts(id, data) {
    return await callApi({
      url: `/api/invoice/${id}/edit/`,
      method: 'POST',
      data
    });
  }

  async getInvoiceInfo(id) {
    return await callApi({ url: `/api/invoices/${id}/` });
  }
  async fiscalizeReceipts(id) {
    return await callApi({
      url: `/api/invoice/${id}/fiscalize/`,
      method: 'POST'
    });
  }
  async createProinvoiceOrInvoice(id, isClosed) {
    return await callApi({
      url: `/api/invoice/${id}/${isClosed ? 'receipt' : 'pre-receipt'}/create/`,
      method: 'POST'
    });
  }
  async sendReceiptsViaEmail(emailListString, id, isClosed) {
    return await callApi({
      url: `/api/invoice/${id}/${isClosed ? 'receipt' : 'pre-receipt'}/send/`,
      method: 'POST',
      data: { mail: emailListString }
    });
  }
  async cancelReceiptFiscalize(id) {
    return await callApi({
      url: `/api/invoice/${id}/cancel/`,
      method: 'POST'
    });
  }
  async deleteReceipt(id) {
    return await callApi({
      url: `/api/invoice/${id}/delete/`,
      method: 'DELETE'
    });
  }
}

export const invoicesService = new InvoicesService();
