import React, { useState } from 'react';
import { Form, Input, Button, Spin } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone, LockFilled } from '@ant-design/icons';
import styles from '../Profile.module.css';
export const Security = ({ updateUserPassword }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const onSubmit = async (values) => {
    setLoading(true);
    await updateUserPassword(values);
    setLoading(false);
  };
  const validatePasswordConfirm = () => {
    let newPassword = form.getFieldValue('new_password');
    let confirmPassword = form.getFieldValue('confirm_password');
    if (newPassword !== confirmPassword) {
      return true;
    } else {
      return false;
    }
  };
  const validateNewPassword = (value) => {
    return /(?=^.{8,}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])/.test(value);
  };
  return (
    <div className={styles.securityTabContainer}>
      <h2>
        <LockFilled /> Uredi sigurnost
      </h2>
      <div className={styles.securityTab}>
        <p>Promijenite lozinku</p>
        <Spin spinning={loading}>
          <Form
            form={form}
            name="userPasswordForm"
            onFinish={onSubmit}
            onFinishFailed={({ errorFields }) => {
              form.scrollToField(errorFields[0].name);
            }}
          >
            <Form.Item
              name="new_password"
              rules={[
                {
                  validator: async (rule, value) => {
                    if (!validateNewPassword(value)) {
                      throw new Error('Lozinka mora sadržati malo slovo, veliko slovo i broj!');
                    }
                  }
                },
                {
                  required: true,
                  message: 'Unesite novu lozinku!'
                }
              ]}
            >
              <Input.Password
                placeholder="Nova lozinka"
                size="middle"
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              rules={[
                {
                  required: true,
                  message: 'Unesite potvrdu lozinke!'
                },
                {
                  validator: async (rule, value) => {
                    if (validatePasswordConfirm()) {
                      throw new Error('Lozinke se ne poklapaju!');
                    }
                  }
                }
              ]}
            >
              <Input.Password
                size="middle"
                placeholder="Potvrdite lozinku"
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className={styles.confirmInfoChangesBtn}>
                Sačuvaj
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </div>
  );
};
