import React from 'react';
import { Modal, Popover, notification } from 'antd';

import { invoicesService } from '../../../services/invoices';
import {
  openErrorNotification,
  openNotificationWithIcon,
  openSuccessNotification,
  openWarningNotification
} from '../../../utils/notifications';

import styles from '../../styles/Registers.module.css';
import { showApiErrors } from '../../../utils/showApiErrors';
import { useHistory } from 'react-router-dom';

export const ActionsTableColCommon = ({ invoice, onClone, onEdit, onDelete }) => {
  const history = useHistory();

  const handleDeleteInvoice = async (id) => {
    try {
      await invoicesService.deleteReceipt(id);
      openSuccessNotification({ message: 'Faktura uspješno obrisana!' });
      await onDelete();
    } catch (e) {
      showApiErrors(e);
    }
  };

  const handlePdfInvoice = async (id, status) => {
    var result;
    try {
      await invoicesService.createProinvoiceOrInvoice(id, status);
      openWarningNotification({ message: 'Zahtjev se obrađuje.' });
      var timer = false;
      var fiscalizeInterval = setInterval(async () => {
        if (timer) {
          clearInterval(fiscalizeInterval);
          result = await invoicesService.getInvoiceInfo(id);
          let current_status = result[status ? 'receipt_status' : 'pre_receipt_status'];
          let url = result[status ? 'receipt' : 'pre_receipt'];
          if (current_status === 'queued') {
            openWarningNotification({ message: 'Zahtjev se još uvijek obrađuje. Provjerite kasnije.' });
          } else if (current_status === 'finished') {
            openSuccessNotification({ message: 'Uspješno.' });
            window.open(url, '_blank').focus();
            await onDelete();
          } else {
            openErrorNotification({ message: 'Došlo je do greške. Potražite pomoć' });
          }
        }
        try {
          result = await invoicesService.getInvoiceInfo(id);
          let current_status = result[status ? 'receipt_status' : 'pre_receipt_status'];
          let url = result[status ? 'receipt' : 'pre_receipt'];
          if (current_status === 'finished') {
            clearInterval(fiscalizeInterval);
            openSuccessNotification({ message: 'Uspješno.' });
            Modal.confirm({
              maskClosable: true,
              title: 'Spremno za preuzimanje!',
              okText: 'Preuzmi PDF',
              okType: 'danger',
              cancelText: 'Odustani',
              onOk: () => window.open(url, '_blank').focus(),
              onCancel() {}
            });
            await onDelete();
          }
        } catch (e) {
          showApiErrors(e);
          clearInterval(fiscalizeInterval);
        }
      }, 1000);
      setTimeout(() => {
        timer = true;
      }, 60000);
    } catch (e) {
      showApiErrors(e);
    }
  };
  const confirmPdfInvoice = () => {
    const { id, status, pre_receipt, receipt } = invoice;
    if (receipt) {
      window.open(receipt, '_blank').focus();
    } else if (pre_receipt && status == 'open') {
      window.open(pre_receipt, '_blank').focus();
    } else {
      Modal.confirm({
        maskClosable: true,
        title: `Da li ste sigurni da želite kreirati ${status === 'open' ? 'profakturu' : 'fakturu'}?`,
        okText: 'Da',
        //okType: 'danger',
        cancelText: 'Ne',
        onOk: () => handlePdfInvoice(id, status === 'closed' || status ==='canceled'),
        onCancel() {}
      });
    }
  };

  const confirmDeleteInvoice = () => {
    const { number, pre_number, id } = invoice;
    Modal.confirm({
      maskClosable: true,
      title: `Da li ste sigurni da želite obrisati ${number ? 'račun ' + number : 'predračun ' + pre_number}?`,
      okText: 'Da',
      okType: 'danger',
      cancelText: 'Ne',
      onOk: () => handleDeleteInvoice(id),
      onCancel() {}
    });
  };

  const handleEditInvoice = () => {
    const { id } = invoice;
    history.push(`/invoices/edit/${id}`);
  };
  const handleCloneInvoice = ()=>{
    const { id } = invoice;
    history.push({
    pathname: `/invoices/add/${id}`,
    state: { isclone: true }
    });
};
  return (
    <div className={styles.actions}>
      <Popover content="Otvori" trigger="hover">
        <div
          onClick={() => {
            confirmPdfInvoice();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 482.14 482.14"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-edit icon-dual"
          >
            {/*<svg   style="enable-background:new 0 0 482.14 482.14;" >*/}
            <path d="M142.024,310.194c0-8.007-5.556-12.782-15.359-12.782c-4.003,0-6.714,0.395-8.132,0.773v25.69   c1.679,0.378,3.743,0.504,6.588,0.504C135.57,324.379,142.024,319.1,142.024,310.194z" />
            <path d="M202.709,297.681c-4.39,0-7.227,0.379-8.905,0.772v56.896c1.679,0.394,4.39,0.394,6.841,0.394   c17.809,0.126,29.424-9.677,29.424-30.449C230.195,307.231,219.611,297.681,202.709,297.681z" />
            <path d="M315.458,0H121.811c-28.29,0-51.315,23.041-51.315,51.315v189.754h-5.012c-11.418,0-20.678,9.251-20.678,20.679v125.404   c0,11.427,9.259,20.677,20.678,20.677h5.012v22.995c0,28.305,23.025,51.315,51.315,51.315h264.223   c28.272,0,51.3-23.011,51.3-51.315V121.449L315.458,0z M99.053,284.379c6.06-1.024,14.578-1.796,26.579-1.796   c12.128,0,20.772,2.315,26.58,6.965c5.548,4.382,9.292,11.615,9.292,20.127c0,8.51-2.837,15.745-7.999,20.646   c-6.714,6.32-16.643,9.157-28.258,9.157c-2.585,0-4.902-0.128-6.714-0.379v31.096H99.053V284.379z M386.034,450.713H121.811   c-10.954,0-19.874-8.92-19.874-19.889v-22.995h246.31c11.42,0,20.679-9.25,20.679-20.677V261.748   c0-11.428-9.259-20.679-20.679-20.679h-246.31V51.315c0-10.938,8.921-19.858,19.874-19.858l181.89-0.19v67.233   c0,19.638,15.934,35.587,35.587,35.587l65.862-0.189l0.741,296.925C405.891,441.793,396.987,450.713,386.034,450.713z    M174.065,369.801v-85.422c7.225-1.15,16.642-1.796,26.58-1.796c16.516,0,27.226,2.963,35.618,9.282   c9.031,6.714,14.704,17.416,14.704,32.781c0,16.643-6.06,28.133-14.453,35.224c-9.157,7.612-23.096,11.222-40.125,11.222   C186.191,371.092,178.966,370.446,174.065,369.801z M314.892,319.226v15.996h-31.23v34.973h-19.74v-86.966h53.16v16.122h-33.42   v19.875H314.892z" />
          </svg>
        </div>
        
      </Popover>
 
      {invoice.total>0 ? (
     <Popover content="Kloniraj" trigger="hover">
     <div
       onClick={() => {
         handleCloneInvoice();
       }}
     >
       <svg
         xmlns="http://www.w3.org/2000/svg"
         width="18"
         height="18"
         viewBox="0 0 24 24"
         fill="none"
         stroke="currentColor"
         strokeWidth="2"
         strokeLinecap="round"
         strokeLinejoin="round"
         className="feather feather-copy icon-dual"
       >
         <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
         <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
       </svg>
     </div>
   </Popover>
      ) : null}
      {invoice.status === 'open' ? (
      <Popover content="Uredi" trigger="hover">
        <div
          onClick={() => {
            handleEditInvoice();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-edit icon-dual"
          >
            <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
            <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
          </svg>
        </div>
      </Popover>
      ) : null}
      {invoice.status === 'open' ? (
        <>
        <Popover content="Obriši" trigger="hover">
          <div onClick={confirmDeleteInvoice}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-trash-2 icon-dual"
            >
              <polyline points="3 6 5 6 21 6"></polyline>
              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
              <line x1="10" y1="11" x2="10" y2="17"></line>
              <line x1="14" y1="11" x2="14" y2="17"></line>
            </svg>
          </div>
        </Popover>
  
</>
        
      ) : null}
    </div>
  );
};
