import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

export const getSelectOptionsWithOrWithoutImg = (options, img = 1) => {
  return options.map((option) => {
    return (
      <Option key={option.id} value={option.id} data-searchvalue={option.name}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {img ? (
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '5px'
              }}
            >
              {option.img ? (
                <img
                  src={`${option.img}`}
                  alt={option.id}
                  style={{
                    maxWidth: '16px',
                    maxHeight: '16px'
                  }}
                />
              ) : (
                ''
              )}
            </span>
          ) : (
            ''
          )}
          {option.name}
        </div>
      </Option>
    );
  });
};
