import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { Modal, Popover, Button, Form, Input, Row, Col, Select } from 'antd';
import { buyersService } from '../../../services/buyers';
import { openSuccessNotification } from '../../../utils/notifications';
import { getSelectOptionsWithOrWithoutImg } from '../../../utils/options';

import styles from '../../styles/Registers.module.css';
import { showApiErrors } from '../../../utils/showApiErrors';

import { useContext } from 'react';
import { CompaniesAndCountriesContext } from '../../../contexts/companiesAndCountriesContext';
import { TrophyOutlined } from '@ant-design/icons';

const { TextArea } = Input;
export const AddOrEditBuyer = ({ visibleModal, onCloseModal, onClone, onEdit, onAdd, buyer, isClone }) => {
  const { countries } = useContext(CompaniesAndCountriesContext);
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [reqVat, setReqVat] = useState(false);
  const [reqReg, setReqReg] = useState(true);
  const [requireMessagePib,setRequireMessagePib] = useState('Popunite PIB!');

  const initialValues = useMemo(() => {
    if (!buyer) {
      return undefined;
    }
    const name = isClone ? buyer.name + ' - klon' : buyer.name;
    const {
      reg_no,
      vat_no,
      address,
      postal_code,
      city,
      country,
      website,
      contact_person,
      contact_phone,
      contact_mail,
      bank_account,
      comment
    } = buyer;
    return {
      name,
      reg_no,
      vat_no,
      address,
      postal_code,
      city,
      country,
      website,
      contact_person,
      contact_phone,
      contact_mail,
      bank_account,
      comment
    };
  }, [buyer, isClone]);

  useEffect(() => {
    async function getCustomers() {
      setLoading(true);
      try {
        const { results } = await buyersService.getAllBuyers();
        setCustomers(results);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setLoading(false);
      }
    }
    getCustomers();
  }, []);

  const resetFields = useCallback(() => {
    form.resetFields();
  }, [form]);
  useEffect(() => {
    form.validateFields(['reg_no']);
  }, [reqReg, requireMessagePib]);
  useEffect(() => {
    form.validateFields(['vat_no']);
  }, [reqVat]);
  useEffect(() => {
    if (visibleModal) {
      resetFields();
    }
  }, [resetFields, visibleModal]);

  const onSubmit = async (values) => {
    setLoading(true);

    if (buyer && !isClone) {
      try {
        await buyersService.editBuyer(buyer.id, values);
        onCloseModal();
        openSuccessNotification({ message: 'Uspješno uređeno!' });
        await onEdit();
      } catch (e) {
        showApiErrors(e);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        await buyersService.addBuyer(values);
        onCloseModal();
        openSuccessNotification({ message: `Kupac uspješno ${isClone ? 'kloniran' : 'kreiran'}!` });
        isClone ? await onClone() : await onAdd();
      } catch (e) {
        showApiErrors(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const type = buyer && isClone ? 'Kloniraj' : buyer ? 'Uredi' : 'Dodaj';
  const updateRequiredFields = ()=>{
    if(form.getFieldValue('country')==141){
      setRequireMessagePib('Popunite PIB!');
      setReqVat(false);
      setReqReg(true);
    }else{
      setRequireMessagePib('Popunite PIB ili PDV!');
      if((!form.getFieldValue('reg_no') |form.getFieldValue('reg_no')=='' )& (form.getFieldValue('vat_no')!=undefined &form.getFieldValue('vat_no')!='')){
        
        setReqReg(false);
        setReqVat(true);
        

      }else if ((!form.getFieldValue('reg_no') | form.getFieldValue('reg_no')=='')  & (!form.getFieldValue('vat_no')| form.getFieldValue('vat_no')=='')){
        setReqReg(true);
        setReqVat(true);
       }else{ 
        setReqReg(true);
        setReqVat(false);
       }
    }
  };
  return (
    <Modal
      title={type + ' kupca'}
      visible={visibleModal}
      wrapClassName={styles.addOrEditRegisterModal}
      onCancel={() => {
        onCloseModal();
        setReqReg(true);
        setReqVat(true);
      }}
      footer={[
        <Popover
          content={'Odustani od ' + (buyer && isClone ? 'kloniranja' : buyer ? 'uređivanja' : 'dodavanja') + ' kupca'}
          trigger="hover"
        >
          <Button
            key="cancel"
            className={styles.cancelButton}
            onClick={() => {
              onCloseModal();
            }}
          >
            Odustani
          </Button>
        </Popover>,

        <Popover content={(buyer && isClone ? 'Kloniraj' : buyer ? 'Uredi' : 'Sačuvaj') + ' kupca'} trigger="hover">
          <Button key="submit" type="primary" loading={loading} onClick={() => form.submit()} className={styles.addButton}>
            Sačuvaj
          </Button>
        </Popover>
      ]}
      width={600}
    >
      <div className={styles.modalFormWrapper}>
        <Form
          form={form}
          initialValues={initialValues}
          onFinish={onSubmit}
          onFinishFailed={({ errorFields }) => {
            form.scrollToField(errorFields[0].name);
          }}
        >
          <div className={styles.nameAndCheckboxFieldsWrapper}>
            <Row gutter={[16, 2]}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="name"
                  label="Naziv"
                  labelCol={{ span: 24 }}
                  className={styles.labelForAddOrEditBuyer}
                  rules={[
                    {
                      required: true,
                      message: 'Popunite polje!'
                    }
                  ]}
                >
                  <Input required placeholder="Naziv privrednog subjekta" autoComplete="off" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label="PIB"
                  initialValue=""
                  labelCol={{ span: 24 }}
                  className={styles.labelForAddOrEditBuyer}
                  normalize={form.getFieldValue('country')== 141 ? (value) => (value || '').replace(/[^0-9^]+/g, ''): null}
                  name="reg_no"
                  rules={[
                    {
                      required:reqReg,
                      message: requireMessagePib
                    },
                    {
                      validator(rule, value, callback) {
                        if (
                          (value || '').length > 0 &&
                          customers.find((c) => c.reg_no === String(value)) &&
                          !(buyer && !isClone && String(value) === buyer.reg_no)
                        ) {
                          callback('Kupac sa ovim PIB-om je već unešen!');
                        } else if ((value || '').length > 0 && (value || '').length != 8 && (value || '').length != 13 && form.getFieldValue('country')== 141) {
                          callback('PIB mora sadržati 8 ili 13 cifara!');
                        }
                        callback();
                      }
                    }
                  ]}
                >
                  <Input
                    placeholder="Unesite PIB"
                    onChange={(e) => {
                      updateRequiredFields();

                    }}
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label="Država"
                  labelCol={{ span: 24 }}
                  className={styles.labelForAddOrEditBuyer}
                  name="country"
                  initialValue={141}
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Popunite polje!'
                    }
                  ]}
                >
                  <Select
                    showSearch
                    dropdownClassName={countries ? styles.selectDropdownWithImg : styles.hideSelectDropdown}
                    optionFilterProp="data-searchvalue"
                    showArrow={false}
                    placeholder="Izaberite državu"
                    notFoundContent={'Nema rezultata'}
                    onChange={()=>{
                      updateRequiredFields();

                    }
}
                    
                  >
                    {getSelectOptionsWithOrWithoutImg(countries || [])}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label="PDV"
                  labelCol={{ span: 24 }}
                  className={styles.labelForAddOrEditBuyer}
                  name="vat_no"
                  rules={[
                    {
                      required: reqVat,
                      message: 'Potrebno je unijeti PIB ili PDV kupca!'
                    },
                    
                    {
                      validator(rule, value, callback) {
                        if (
                          (value || '').length > 0 &&
                          customers.find((c) => c.vat_no === String(value)) &&
                          !(buyer && !isClone && String(value) === buyer.vat_no)
                        ) {
                          callback('Kupac sa ovim PDV-om je već unešen!');
                        }
                        callback();
                      }
                    }
                  ]}
                >
                  <Input
                     onChange={()=>{
                      updateRequiredFields();

                    }}
                    placeholder="Unesite PDV"
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <span>Adresa</span>
                <Form.Item name="address">
                  <Input placeholder="Unesite adresu" autoComplete="off" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <span>Grad</span>
                <Form.Item name="city">
                  <Input placeholder="Unesite grad" autoComplete="off" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <span>Poštanski broj</span>
                <Form.Item name="postal_code" normalize={(value) => (value || '').replace(/[^0-9+^]/g, '')}>
                  <Input placeholder="Unesite poštanski broj" autoComplete="off" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <span>Sajt</span>
                <Form.Item name="website">
                  <Input placeholder="Unesite link sajta" autoComplete="off" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <span>Kontakt osoba</span>
                <Form.Item name="contact_person">
                  <Input placeholder="Unesite kontakt osobu" autoComplete="off" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <span>Kontakt telefon</span>
                <Form.Item name="contact_phone" normalize={(value) => (value || '').replace(/[^0-9+^]+/g, '')}>
                  <Input placeholder="Unesite kontakt telefon" autoComplete="off" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <span>Kontakt mejl</span>
                <Form.Item
                  name="contact_mail"
                  rules={[
                    {
                      type: 'email',
                      message: 'Unešeni mejl nije ispravan'
                    }
                  ]}
                >
                  <Input placeholder="Unesite kontakt mejl" autoComplete="off" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24}>
                <span>Žiro račun</span>
                <Form.Item name="bank_account">
                  <Input placeholder="Unesite žiro račun" autoComplete="off" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24}>
                <span>Komentar</span>
                <Form.Item name="comment">
                  <TextArea placeholder="Unesite komentar" showCount maxLength={200} />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
