import React, { useState } from 'react';
import { Modal, Popover } from 'antd';

import { buyersService } from '../../../services/buyers';
import { openSuccessNotification } from '../../../utils/notifications';

import styles from '../../styles/Registers.module.css';
import { showApiErrors } from '../../../utils/showApiErrors';

export const ActionsTableColCommon = ({ buyer, onClone, onEdit, onDelete, renderModal }) => {
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const [isClone, setIsClone] = useState(false);

  const handleOpenEditModal = () => setVisibleEditModal(true);

  const handleCloseEditModal = () => setVisibleEditModal(false);

  const handleDeleteBuyer = async (id) => {
    try {
      await buyersService.deleteBuyer(id);
      openSuccessNotification({ message: 'Kupac uspješno obrisan!' });
      await onDelete();
    } catch (e) {
      showApiErrors(e);
    }
  };

  const confirmDeleteBuyer = () => {
    const { name, id } = buyer;
    Modal.confirm({
      maskClosable: true,
      title: `Da li ste sigurni da želite obrisati ${name}?`,
      okText: 'Da',
      okType: 'danger',
      cancelText: 'Ne',
      onOk: () => handleDeleteBuyer(id),
      onCancel() {}
    });
  };

  const commonModalProps = { buyer, visibleModal: visibleEditModal, isClone, onCloseModal: handleCloseEditModal, onClone, onEdit };

  return (
    <div className={styles.actions}>
      <Popover content="Kloniraj" trigger="hover">
        <div
          onClick={() => {
            setIsClone(true);
            handleOpenEditModal();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-copy icon-dual"
          >
            <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
          </svg>
        </div>
      </Popover>

      <Popover content="Uredi" trigger="hover">
        <div
          onClick={() => {
            setIsClone(false);
            handleOpenEditModal();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-edit icon-dual"
          >
            <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
            <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
          </svg>
        </div>
      </Popover>

      <Popover content="Obriši" trigger="hover">
        <div onClick={confirmDeleteBuyer}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-trash-2 icon-dual"
          >
            <polyline points="3 6 5 6 21 6"></polyline>
            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
            <line x1="10" y1="11" x2="10" y2="17"></line>
            <line x1="14" y1="11" x2="14" y2="17"></line>
          </svg>
        </div>
      </Popover>

      {renderModal(commonModalProps)}
    </div>
  );
};
