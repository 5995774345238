import React from 'react';
import { /*HomeOutlined,*/ UserOutlined, CalculatorOutlined, UsergroupAddOutlined, FileTextOutlined } from '@ant-design/icons';
// import { Dashboard } from '../pages/dashboard';
import { Registers } from '../pages/registers';
import { UserProfile } from '../pages/general/index';
import { Buyers } from '../pages/buyers/index';
import { Invoices } from '../pages/invoices';
import { AddorEditInvoice } from '../pages/invoices/components/AddOrEditInvoices';

export const appRoutes = [
  // {
  //   title: 'Kontrolna tabla',
  //   path: '/',
  //   component: Dashboard,
  //   icon: <HomeOutlined />
  //   // permission_name: 'view_dashboard'
  // },
  {
    title: 'Fakture',
    path: ['/', '/invoices'],
    component: Invoices,
    icon: <FileTextOutlined />
    // permission_name: 'view_invoices'
  },
  {
    title: 'Dodaj fakturu',
    path: '/invoices/add',
    component: AddorEditInvoice,
    permission_name: 'view_invoices',
    skipRender: true
  },
  {
    title: 'Dodaj fakturu',
    path: '/invoices/add/:id',
    component: AddorEditInvoice,
    permission_name: 'view_invoices',
    skipRender: true
  },
  {
    title: 'Uredi fakturu',
    path: '/invoices/edit/:id',
    component: AddorEditInvoice,
    permission_name: 'view_invoices',
    skipRender: true
  },
  {
    title: 'Kupci',
    path: '/customers',
    component: Buyers,
    icon: <UsergroupAddOutlined />
    // permission_name: 'view_invoices'
  },
  {
    title: 'Šifarnik',
    path: '/registers',
    component: Registers,
    icon: <CalculatorOutlined />
    // permission_name: 'view_invoices'
  },
  {
    title: 'Profil',
    path: '/my-profile',
    component: UserProfile,
    icon: <UserOutlined />
    // permission_name: 'view_invoices'
  }
];

// TO DO - when permission finished on backend add logic for route permission
export const getAllowedRoutes = () => {
  return appRoutes;
};
