import React, { useState, useEffect } from 'react';
import { registersService } from '../../services/registers';
import { Table } from './components/Table';
import { useDebounce, useFetch } from '../../utils/hooks';
import styles from '../styles/Registers.module.css';
import { Heading } from './components/Heading';
import { AddOrEditRegister } from './components/AddOrEditRegister';

export const Registers = () => {
  const loadingSelectData = false;
  const [searchValue, setSearchValue] = useState('');
  const [orderValue, setOrderValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const size = localStorage.getItem('pageSizeRegisters')? localStorage.getItem('pageSizeRegisters') : 20;
  const [pageSize, setPageSize] = useState(size);
  const [visibleAddModal, setVisibleAddModal] = useState(false);
  const debouncedSearchValue = useDebounce(searchValue);
  const loadRegisters = (cancelToken) =>
    registersService.getRegistersPaged({ currentPage, pageSize, searchValue: debouncedSearchValue, cancelToken, orderValue });

  const [
    {
      data: { results: registers, count: totalRows },
      isFetching
    },
    getRegisters
  ] = useFetch(loadRegisters);

  const loading = isFetching || loadingSelectData;

  useEffect(() => {
    setCurrentPage(1);
    setOrderValue('');
  }, [debouncedSearchValue]);

  useEffect(() => {
    getRegisters();
  }, [debouncedSearchValue, pageSize, currentPage, getRegisters, orderValue]);
  return (
    <div className={styles.registers}>
      <h1>Šifarnik</h1>

      <div className={styles.invoiceAndBuyerFormBoxes}>
        <Heading
          searchValue={searchValue}
          onSearch={(search) => setSearchValue(search)}
          onCreateRegisterClicked={() => setVisibleAddModal(true)}
        />
        <Table
          registers={registers}
          loading={loading}
          totalRows={totalRows}
          currentPage={currentPage}
          pageSize={pageSize}
          onClone={getRegisters}
          onEdit={getRegisters}
          onDelete={getRegisters}
          orderValue={orderValue}
          setOrderValue={(order) => setOrderValue(order)}
          onPaginationChange={(page) => setCurrentPage(page)}
          onPageSizeChange={(size) => {
            setCurrentPage(1);
            setPageSize(size);
            localStorage.setItem('pageSizeRegisters', size);
          }}
        />
      </div>
      <AddOrEditRegister visibleModal={visibleAddModal} onCloseModal={() => setVisibleAddModal(false)} onAdd={getRegisters} />
    </div>
  );
};
